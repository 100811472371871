import React, { createContext, useContext, useMemo } from "react";
import { i18n } from "@lingui/core";
import { I18nProvider as LinguiProvider } from "@lingui/react";
import { pageContext } from "../PageProvider/PageProvider";
import { defaultTranslations, Translations, useTranslations } from "./useTranslations";
import { setupI18n } from "../../utils/i18n";

setupI18n();

interface I18nContext {
  translations: Translations;
}

export const i18nContext = createContext<I18nContext>({
  translations: defaultTranslations,
});

export const I18nProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const { locale } = useContext(pageContext);

  if (i18n.locale !== locale) {
    i18n.activate(locale);
  }

  const translations = useTranslations(locale);
  const context = useMemo(
    () => ({
      translations,
    }),
    [translations]
  );

  return (
    <LinguiProvider i18n={i18n}>
      <i18nContext.Provider value={context}>{children}</i18nContext.Provider>
    </LinguiProvider>
  );
};
