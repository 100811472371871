import * as path from "path";
import { FoodExplorerMode } from "./ecosystems/food-explorer/hooks/useFoodExplorerModes";
import { FoodExplorerFoodItem } from "./ecosystems/food-explorer/types";
import { DoctorFilterOptions } from "./graphql/useDoctors/useDoctors";
import { MealPlanPage } from "./ecosystems/mealplans/pages";
import { createExternalPage, createRegularPage } from "./page-utils";
import { Doctor, PartialDoctor } from "./types/directus/Doctor";
import { Locale } from "./types/Locale";
import { Layout } from "./utils/layout";

export type Page<Context = undefined, Params = undefined, Query = undefined> =
  | InternalPage<Context, Params, Query>
  | ExternalPage<Params, Query>;

export type InternalPage<Context, Params, Query> =
  | RegularPage<Context, Params, Query>
  | ClientSidePage<Context, Params, Query>;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface RegularPage<_ = undefined, Params = undefined, Query = undefined> {
  type: "regular";
  name: string;
  component: string;
  path: (locale: Locale, params: Params, query: Query) => string;
  processParamsAndQuery?: (
    locale: Locale,
    staticParams: Params,
    dynamicParams: Partial<Record<string, string>>,
    query: Partial<Record<string, string>>
  ) => [Params, Query];
  layout?: Layout;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export interface ClientSidePage<_ = undefined, Params = undefined, Query = undefined> {
  type: "clientside";
  name: string;
  component: string;
  path: (locale: Locale, params: Params, query: Query) => string;
  matchPath: (locale: Locale) => string;
  routerPaths: (locale: Locale) => string[];
  processParamsAndQuery?: (
    locale: Locale,
    staticParams: Params,
    dynamicParams: Partial<Record<string, string>>,
    query: Partial<Record<string, string>>
  ) => [Params, Query];
  layout?: Layout;
}

export interface ExternalPage<Params = undefined, Query = undefined> {
  type: "external";
  name: string;
  path: (locale: Locale, params: Params, query: Query) => string;
}

export interface PageContext<Context, Params, Query> {
  name: string;
  locale: Locale;
  params: Params;
  query: Query;
  extraContext: Context;
  layout?: Layout;
}

export type GetPageContext<P> = P extends Page<infer Context, infer Params, infer Query>
  ? PageContext<Context, Params, Query>
  : never;

export function isClientSidePage<Context, Params, Query>(
  page: Page<Context, Params, Query>
): page is ClientSidePage<Context, Params, Query> {
  return page.type === "clientside";
}

export const localePrefix: Record<Locale, string> = {
  [Locale.EN]: "",
  [Locale.ES]: "/es",
  [Locale.SV]: "/se",
  [Locale.ZU]: "/test",
};

export const DoctorsPage = createRegularPage<{
  doctors: PartialDoctor[];
  filterOptions: DoctorFilterOptions;
}>({
  name: "doctors.main",
  component: path.resolve("./src/ecosystems/doctors/DoctorsPage/DoctorsPage.tsx"),
  path: (locale) => `${localePrefix[locale]}/low-carb/doctors`,
});

export const AllDoctorsPage = createRegularPage<{
  doctors: Doctor[];
}>({
  name: "doctors.all",
  component: path.resolve("./src/ecosystems/doctors/AllDoctorsPage/AllDoctorsPage.tsx"),
  path: (locale) => `${localePrefix[locale]}/low-carb/doctors/all`,
});

export const DoctorProfilePage = createRegularPage<{ doctor: Doctor }, { slug: string }>({
  name: "doctors.profile",
  component: path.resolve("./src/ecosystems/doctors/DoctorProfilePage/DoctorProfilePage.tsx"),
  path: (locale, { slug }) => `${localePrefix[locale]}/low-carb/doctor/${slug}`,
});

export const MyFavoritesPage = createExternalPage({
  name: "my.favorites",
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/member/favorites`;
      case Locale.ES:
        return `${localePrefix[locale]}/miembro/mis-favoritos`;
      case Locale.SV:
        return `${localePrefix[locale]}/medlem/favoriter`;
    }
  },
});

export const ShoppingListPage = createRegularPage({
  name: "shopping-list",
  component: path.resolve("./src/ecosystems/shopping-list/ShoppingListPage.tsx"),
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/shopping-list`;
      case Locale.ES:
        return `${localePrefix[locale]}/lista-compras`;
      case Locale.SV:
        return `${localePrefix[locale]}/inkopslista`;
    }
  },
});

export const CMEPage = createExternalPage({
  name: "cme",
  path: () => "/cme",
});

export const DDProAllResourcesPage = createExternalPage({
  name: "ddproresources",
  path: () => "/ddpro/resources",
});

export const DDProPage = createRegularPage({
  name: "ddpro",
  component: path.resolve("./src/ecosystems/dd-pro/pro/DDPro.tsx"),
  layout: Layout.NO_FOOTER,
  path: (locale) => `${localePrefix[locale]}/pro`,
});

export const DDProAboutPage = createRegularPage({
  name: "ddproabout",
  component: path.resolve("./src/ecosystems/dd-pro/pro/DDPro.tsx"),
  layout: Layout.NO_FOOTER,
  path: (locale) => `${localePrefix[locale]}/pro/about`,
});

export const DDProAddPage = createRegularPage({
  name: "ddproadd",
  component: path.resolve("./src/ecosystems/dd-pro/pro/DDPro.tsx"),
  layout: Layout.NO_FOOTER,
  path: (locale) => `${localePrefix[locale]}/pro/add`,
});

export const DDProPDFPage = createRegularPage({
  name: "ddpropdf",
  component: path.resolve("./src/ecosystems/dd-pro/pro/DDProPDF.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => `${localePrefix[locale]}/pro/qr`,
});

export const DDProJoinPage = createRegularPage({
  name: "ddprojoin",
  component: path.resolve("./src/ecosystems/dd-pro/join/DDProJoin.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => `${localePrefix[locale]}/pro/join`,
});

export const DDProJoinExistingPage = createRegularPage({
  name: "ddprojoinexisting",
  component: path.resolve("./src/ecosystems/dd-pro/join/DDProJoinCheckExisting.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => `${localePrefix[locale]}/pro/user-join`,
});

export const DDProJoinCheckExistingPage = createRegularPage({
  name: "ddprojoincheckexisting",
  component: path.resolve("./src/ecosystems/dd-pro/join/DDProJoinCheckExisting.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => `${localePrefix[locale]}/pro/user-join/check`,
});

export const DDProJoinConfirmExistingPage = createRegularPage({
  name: "ddprojoinconfirmexisting",
  component: path.resolve("./src/ecosystems/dd-pro/join/DDProJoinCheckExisting.tsx"),
  layout: Layout.NO_FOOTER,
  path: (locale) => `${localePrefix[locale]}/pro/user-join/confirm`,
});

export const MyMealPlansPage = createRegularPage({
  name: "mealplans.list",
  component: path.resolve("./src/components/MealPlannerPage/MealPlannerPage.tsx"),
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/low-carb/meal-plans/my-meal-planner`;
      case Locale.ES:
        return `${localePrefix[locale]}/low-carb/menus/mi-planificador`;
      case Locale.SV:
        return `${localePrefix[locale]}/medlem/veckomenyer/mina-veckomenyer`;
    }
  },
});

export const AllMealPlansPage = createExternalPage({
  name: "mealplans.all",
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/low-carb/meal-plans/all`;
      case Locale.ES:
        return `${localePrefix[locale]}/low-carb/menus/todos`;
      case Locale.SV:
        return `${localePrefix[locale]}/medlem/veckomenyer/alla`;
    }
  },
});

export const LoginPage = createRegularPage<undefined, undefined, { redirectTo?: string }>({
  name: "login",
  component: path.resolve("./src/components/LoginPage/LoginPage.tsx"),
  path: (locale, params, { redirectTo } = {}) => {
    const redirectToPart = redirectTo ? `?redirect_to=${redirectTo}` : "";
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/login${redirectToPart}`;
      case Locale.ES:
        return `${localePrefix[locale]}/iniciar-sesion${redirectToPart}`;
      case Locale.SV:
        return `${localePrefix[locale]}/login${redirectToPart}`;
    }
  },
});

export const WelcomePage = createRegularPage<undefined, undefined, { content?: string }>({
  name: "welcome",
  component: path.resolve("./src/ecosystems/welcome/WelcomePage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/welcome`;
      case Locale.ES:
        return `${localePrefix[locale]}/bienvenida`;
      case Locale.SV:
        return `${localePrefix[locale]}/valkommen-2`;
    }
  },
});

export const UserPersonalSettingsPage = createRegularPage({
  name: "user.personal.settings",
  component: path.resolve("./src/ecosystems/user/PersonalSettingsPage.tsx"),
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/user/settings`;
      case Locale.ES:
        return `${localePrefix[locale]}/usuario/ajustes`;
      case Locale.SV:
        return `${localePrefix[locale]}/anvandare/installningar`;
    }
  },
});

export const UserFoodPreferencesPage = createRegularPage({
  name: "user.food.preferences",
  component: path.resolve("./src/ecosystems/user/FoodPreferencesPage.tsx"),
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/user/food-preferences`;
      case Locale.ES:
        return `${localePrefix[locale]}/usuario/preferencias-comida`;
      case Locale.SV:
        return `${localePrefix[locale]}/anvandare/matpreferenser`;
    }
  },
});

export const HomePage = createExternalPage({
  name: "home",
  path: (locale) => {
    const path = `${localePrefix[locale]}`;
    return path === "" ? "/" : path;
  },
});

// Logged-in Home
export const LoggedInHomePage = createRegularPage({
  name: "logged-in-home",
  component: path.resolve("./src/ecosystems/logged-in-home/LoggedInHomePage.tsx"),
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/home`;
      case Locale.ES:
        return `${localePrefix[locale]}/inicio`;
      case Locale.SV:
        return `${localePrefix[locale]}/hem`;
    }
  },
});

// Logged-out Home
export const LoggedOutHomePage = createRegularPage({
  name: "logged-out-home",
  component: path.resolve("./src/ecosystems/logged-out-home/LoggedOutHomePage.tsx"),
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/`;
      case Locale.ES:
        return `${localePrefix[locale]}/`;
      case Locale.SV:
        return `${localePrefix[locale]}/`;
    }
  },
});

export const LoggedOutHomePageAB = createRegularPage({
  name: "logged-out-home-ab",
  component: path.resolve("./src/ecosystems/logged-out-home/LoggedOutHomePage.tsx"),
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-home`;
      case Locale.ES:
        return `${localePrefix[locale]}/nuevo-inicio`;
      case Locale.SV:
        return `${localePrefix[locale]}/ny-hem`;
    }
  },
});

// Braze preference center
export const BrazePreferenceCenterRedirectPage = createRegularPage({
  name: "braze-preference-center",
  component: path.resolve("./src/ecosystems/braze/BrazePreferenceCenterRedirectPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/braze/preference-center`;
      case Locale.ES:
        return `${localePrefix[locale]}/braze/preference-center`;
      case Locale.SV:
        return `${localePrefix[locale]}/braze/preference-center`;
    }
  },
});

// Main new member page
export const NewMemberPage = createRegularPage({
  name: "new-member-v3",
  component: path.resolve("./src/ecosystems/new-member/NewMemberPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member`;
      case Locale.ES:
        return `${localePrefix[locale]}/nuevo-miembro-v3`;
      case Locale.SV:
        return `${localePrefix[locale]}/ny-medlem`;
    }
  },
});

export const NewMemberPagePodcast = createRegularPage({
  name: "new-member-podcast",
  component: path.resolve("./src/ecosystems/new-member/NewMemberPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member/podcast`;
      case Locale.ES:
        return `${localePrefix[locale]}/nuevo-miembro/podcast`;
      case Locale.SV:
        return `${localePrefix[locale]}/ny-medlem/podcast`;
    }
  },
});

export const NewMemberPageCommunity = createRegularPage({
  name: "new-member-community",
  component: path.resolve("./src/ecosystems/new-member/NewMemberPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member/community`;
      case Locale.ES:
        return `${localePrefix[locale]}/nuevo-miembro/community`;
      case Locale.SV:
        return `${localePrefix[locale]}/ny-medlem/community`;
    }
  },
});

export const NewMemberPageGuide = createRegularPage({
  name: "new-member-guide",
  component: path.resolve("./src/ecosystems/new-member/NewMemberPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member/guide`;
      case Locale.ES:
        return `${localePrefix[locale]}/nuevo-miembro/guide`;
      case Locale.SV:
        return `${localePrefix[locale]}/ny-medlem/guide`;
    }
  },
});

export const NewMemberPageProgram = createRegularPage({
  name: "new-member-program",
  component: path.resolve("./src/ecosystems/new-member/NewMemberPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member/program`;
      case Locale.ES:
        return `${localePrefix[locale]}/nuevo-miembro/program`;
      case Locale.SV:
        return `${localePrefix[locale]}/ny-medlem/program`;
    }
  },
});

export const NewMemberPageVideo = createRegularPage({
  name: "new-member-video",
  component: path.resolve("./src/ecosystems/new-member/NewMemberPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member/video`;
      case Locale.ES:
        return `${localePrefix[locale]}/nuevo-miembro/video`;
      case Locale.SV:
        return `${localePrefix[locale]}/ny-medlem/video`;
    }
  },
});

export const NewMemberPageMealPlan = createRegularPage({
  name: "new-member-meal-plan",
  component: path.resolve("./src/ecosystems/new-member/NewMemberPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member/meal-plan`;
      case Locale.ES:
        return `${localePrefix[locale]}/nuevo-miembro/meal-plan`;
      case Locale.SV:
        return `${localePrefix[locale]}/ny-medlem/meal-plan`;
    }
  },
});

export const NewMemberPageFreeTrial = createRegularPage({
  name: "new-member-free-trial",
  component: path.resolve("./src/ecosystems/new-member/NewMemberPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ES:
      case Locale.SV:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member-free-trial`;
    }
  },
});

export const NewMemberMealPlanPage = createExternalPage<undefined, { redirectTo?: string }>({
  name: "new-member.mealplans",
  path: (locale, params, { redirectTo }) => {
    const redirectToPart = redirectTo ? `?redirect_to=${redirectTo}` : "";

    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member/meal-plan${redirectToPart}`;
      case Locale.ES:
        return NewMemberPage.path(locale, undefined, undefined); // FIXME
      case Locale.SV:
        return NewMemberPage.path(locale, undefined, undefined); // FIXME
    }
  },
});

export const NewMemberPersonalizationPage = createRegularPage({
  name: "new-member.personalization",
  component: path.resolve("./src/components/Personalization/MealPlanPreviewPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member/personalization`;
      case Locale.ES:
        return `${localePrefix[locale]}/nuevo-miembro/personalizacion`;
      case Locale.SV:
        return `${localePrefix[locale]}/ny-medlem/anpassning`;
    }
  },
});

// Quiz landing pages
export const QuizLandingPage = createRegularPage({
  name: "quiz-landing-page-v1",
  component: path.resolve("./src/ecosystems/quiz-landing-page/QuizLandingPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/lp/quiz`;
      case Locale.ES:
        return `${localePrefix[locale]}/lp/quiz`;
      case Locale.SV:
        return `${localePrefix[locale]}/lp/quiz`;
    }
  },
});

export const QuizLandingPageAlias = createRegularPage({
  name: "quiz-landing-page-v1-alias",
  component: path.resolve("./src/ecosystems/quiz-landing-page/QuizLandingPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/lpg/quiz`;
      case Locale.ES:
        return `${localePrefix[locale]}/lpg/quiz`;
      case Locale.SV:
        return `${localePrefix[locale]}/lpg/quiz`;
    }
  },
});

export const QuizLandingPageSecondAlias = createRegularPage({
  name: "quiz-landing-page-v1-second-alias",
  component: path.resolve("./src/ecosystems/quiz-landing-page/QuizLandingPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/fb1-meal-plans`;
      case Locale.ES:
        return `${localePrefix[locale]}/fb1-meal-plans`;
      case Locale.SV:
        return `${localePrefix[locale]}/fb1-meal-plans`;
    }
  },
});

// Projection page
export const PersonalizedPlanProjectionPage = createRegularPage<undefined, undefined, { content?: string }>({
  name: "new-member.personalized-plan-projection",
  component: path.resolve("./src/ecosystems/onboarding-AB-test/pages/PersonalizedPlanProjectionPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale, params, { content } = {}) => {
    const contentPart = content ? `?content=${content}` : "";
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member/personalized-plan-projection${contentPart}`;
      case Locale.ES:
        return `${localePrefix[locale]}/new-member/personalized-plan-projection${contentPart}`;
      case Locale.SV:
        return `${localePrefix[locale]}/new-member/personalized-plan-projection${contentPart}`;
    }
  },
});
export const PersonalizedPlanProjectionPagev2 = createRegularPage<undefined, undefined, { content?: string }>({
  name: "new-member.personalized-plan-projection-v2",
  component: path.resolve("./src/ecosystems/onboarding-v2/pages/PersonalizedPlanProjectionPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale, params, { content } = {}) => {
    const contentPart = content ? `?content=${content}` : "";
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member/personalized-plan-projection-v2${contentPart}`;
      case Locale.ES:
        return `${localePrefix[locale]}/new-member/personalized-plan-projection-v2${contentPart}`;
      case Locale.SV:
        return `${localePrefix[locale]}/new-member/personalized-plan-projection-v2${contentPart}`;
    }
  },
});

export const PersonalizedPlanSalesPage = createRegularPage<undefined, undefined, { content?: string }>({
  name: "new-member.personalized-plan-sales",
  component: path.resolve("./src/ecosystems/onboarding-AB-test/pages/PersonalizedPlanSalesPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale, params, { content } = {}) => {
    const contentPart = content ? `?content=${content}` : "";
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member/personalized-plan-sales${contentPart}`;
      case Locale.ES:
        return `${localePrefix[locale]}/new-member/personalized-plan-sales${contentPart}`;
      case Locale.SV:
        return `${localePrefix[locale]}/new-member/personalized-plan-sales${contentPart}`;
    }
  },
});

export const PersonalizedPlanSalesPagev2 = createRegularPage<undefined, undefined, { content?: string }>({
  name: "new-member.personalized-plan-sales-v2",
  component: path.resolve("./src/ecosystems/onboarding-v2/pages/PersonalizedPlanSalesPage.tsx"),
  layout: Layout.NO_LAYOUT,
  path: (locale, params, { content } = {}) => {
    const contentPart = content ? `?content=${content}` : "";
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/new-member/personalized-plan-sales-v2${contentPart}`;
      case Locale.ES:
        return `${localePrefix[locale]}/new-member/personalized-plan-sales-v2${contentPart}`;
      case Locale.SV:
        return `${localePrefix[locale]}/new-member/personalized-plan-sales-v2${contentPart}`;
    }
  },
});

export const RecipePage = createExternalPage<{ slug: string; servings?: number }>({
  name: "recipes.recipe",
  path: (locale, { slug, servings }) => {
    let servingsPart: string;

    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        servingsPart = typeof servings === "undefined" ? "" : `/servings/${servings}`;
        return `${localePrefix[locale]}/recipes/${slug}${servingsPart}`;
      case Locale.ES:
        servingsPart = typeof servings === "undefined" ? "" : `/porciones/${servings}`;
        return `${localePrefix[locale]}/recetas/${slug}${servingsPart}`;
      case Locale.SV:
        servingsPart = typeof servings === "undefined" ? "" : `/portioner/${servings}`;
        return `${localePrefix[locale]}/recept/${slug}${servingsPart}`;
    }
  },
});

export const RecipesFaqPageSchema = createExternalPage<undefined, { question?: number }>({
  name: "recipes.faq",
  path: (locale, params, { question }) => {
    const questionPart = typeof question === "number" ? `#${question}` : "";

    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/low-carb/recipes/faq${questionPart}`;
      case Locale.ES:
        return `${localePrefix[locale]}/low-carb/recetas/preguntas-frecuentes${questionPart}`;
      case Locale.SV:
        return `${localePrefix[locale]}/lchf/recept/faq${questionPart}`;
    }
  },
});

export enum SignUpStep {
  ACCOUNT = "account",
  PLAN = "plan",
  PAYMENT = "payment",
}

interface SignUpPageParams {
  content?: string;
  source?: string;
  step?: string;
}

export const SignUpPage = createExternalPage<SignUpPageParams>({
  name: "signup",
  path: (locale, { content, source, step = SignUpStep.ACCOUNT }) => {
    const contentParam = content ? `?content=${content}` : "";
    const sourceParam = contentParam && source ? `&source=${source}` : "";

    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/become-member/${step}${contentParam}${sourceParam}`;
      case Locale.ES:
        return `${localePrefix[locale]}/hazte-miembro/${step}${contentParam}${sourceParam}`;
      case Locale.SV:
        return `${localePrefix[locale]}/bli-medlem/${step}${contentParam}${sourceParam}`;
    }
  },
});

export const AboutPage = createExternalPage({
  name: "about",
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/about`;
      case Locale.ES:
        return `${localePrefix[locale]}/acerca-de`;
      case Locale.SV:
        return `${localePrefix[locale]}/om`;
    }
  },
});

export const CareersPage = createExternalPage({
  name: "careers",
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
      case Locale.ES:
        return `${localePrefix[locale]}/about/careers`;
      case Locale.SV:
        return `${localePrefix[locale]}/jobb`;
    }
  },
});

export const ContactPage = createExternalPage({
  name: "contact",
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return "https://dietdoctor.zendesk.com/hc/en-us";
      case Locale.ES:
        return "https://dietdoctor.zendesk.com/hc/es";
      case Locale.SV:
        return "https://dietdoctor.zendesk.com/hc/sv";
    }
  },
});

export const TeamPage = createExternalPage({
  name: "team",
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/about/team-diet-doctor`;
      case Locale.ES:
        return `${localePrefix[locale]}/acerca-de/equipo-diet-doctor`;
      case Locale.SV:
        return `${localePrefix[locale]}/om/team-diet-doctor-se`;
    }
  },
});

export const HighProteinInfoPage = createExternalPage({
  name: "highprotein",
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/high-protein/foods`;
      case Locale.ES:
        return `${localePrefix[locale]}/low-carb/proteina`;
      case Locale.SV:
        return `${localePrefix[locale]}/lchf/protein-och-lchf`;
    }
  },
});

export const KetoInfoPage = createExternalPage({
  name: "keto",
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/low-carb/keto/how-low-carb-is-keto`;
      case Locale.ES:
        return `${localePrefix[locale]}/keto/cuantos-carbohidratos`;
      case Locale.SV:
        return `${localePrefix[locale]}/lchf/hur-lite-kolhydrater-innebar-lchf`;
    }
  },
});

export const ModerateLiberalInfoPage = createExternalPage({
  name: "moderateLiberal",
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/low-carb/how-low-carb-is-low-carb`;
      case Locale.ES:
        return `${localePrefix[locale]}/keto/cuantos-carbohidratos`;
      case Locale.SV:
        return `${localePrefix[locale]}/lchf/hur-lite-kolhydrater-innebar-lchf`;
    }
  },
});

export const AppPage = createExternalPage({
  name: "app",
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/download-app`;
      case Locale.ES:
        return `${localePrefix[locale]}/download-app`;
      case Locale.SV:
        return `${localePrefix[locale]}/download-app`;
    }
  },
});

export enum TermsSection {
  TERMS = "comments",
  COPYRIGHT = "copyright",
  PRIVACY_POLICY = "privacy-policy",
  DISCLAIMER = "disclaimer",
}

const termsSectionEnumToParam: Record<Locale, Record<TermsSection, string>> = {
  [Locale.EN]: {
    [TermsSection.TERMS]: "terms",
    [TermsSection.COPYRIGHT]: "copyright",
    [TermsSection.PRIVACY_POLICY]: "privacypolicy",
    [TermsSection.DISCLAIMER]: "disclaimer",
  },
  [Locale.ES]: {
    [TermsSection.TERMS]: "terminos",
    [TermsSection.COPYRIGHT]: "copyright",
    [TermsSection.PRIVACY_POLICY]: "privacidad",
    [TermsSection.DISCLAIMER]: "descargo",
  },
  [Locale.SV]: {
    [TermsSection.TERMS]: "anvandarpolicy",
    [TermsSection.COPYRIGHT]: "copyright",
    [TermsSection.PRIVACY_POLICY]: "privacypolicy",
    [TermsSection.DISCLAIMER]: "disclaimer",
  },
  [Locale.ZU]: {
    [TermsSection.TERMS]: "test-terms",
    [TermsSection.COPYRIGHT]: "test-copyright",
    [TermsSection.PRIVACY_POLICY]: "test-privacypolicy",
    [TermsSection.DISCLAIMER]: "test-disclaimer",
  },
};

export const TermsPage = createExternalPage<undefined, { section?: TermsSection }>({
  name: "terms",
  path: (locale, params, { section }) => {
    const sectionPart = typeof section === "undefined" ? "" : `#${termsSectionEnumToParam[locale][section]}`;

    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/terms${sectionPart}`;
      case Locale.ES:
        return `${localePrefix[locale]}/condiciones${sectionPart}`;
      case Locale.SV:
        return `${localePrefix[locale]}/anvandarpolicy${sectionPart}`;
    }
  },
});

export const SearchPage = createRegularPage<undefined, undefined, SearchPageQuery>({
  name: "search",
  component: path.resolve("./src/ecosystems/search/SearchPage.tsx"),
  path: (locale, _, query) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
      case Locale.ES:
      case Locale.SV:
        return `${localePrefix[locale]}/search${query.s ? `?s=${query.s}` : ""}`;
    }
  },
});

export const TrackingPage = createRegularPage({
  name: "tracking",
  component: path.resolve("./src/ecosystems/tracking/TrackingPage.tsx"),
  path: (locale) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/tracking`;
      case Locale.ES:
        return `${localePrefix[locale]}/seguimiento`;
      case Locale.SV:
        return `${localePrefix[locale]}/viktforandring`;
    }
  },
});

interface SearchPageQuery {
  s?: string;
  t?: string;
}

export const NotFoundPage = createRegularPage({
  name: "404",
  component: path.resolve("./src/components/NotFoundPage/NotFoundPage.tsx"),
  path: (locale) => `${localePrefix[locale]}/404`,
});

export const PaymentPage = createExternalPage<undefined, { content?: string }>({
  name: "payment",
  path: (locale, _, query) => {
    switch (locale) {
      case Locale.EN:
      case Locale.ZU:
        return `${localePrefix[locale]}/become-member/payment?content=${query.content}`;
      case Locale.ES:
        return `${localePrefix[locale]}/hazte-miembro/payment?content=${query.content}`;
      case Locale.SV:
        return `${localePrefix[locale]}/bli-medlem/payment?content=${query.content}`;
    }
  },
});

export const RegisterForDDProPage = createExternalPage({
  name: "registerforddpro",
  path: () => "/ddpro",
});

export const SatietyScorePage = createExternalPage({
  name: "satietyScore",
  path: () => "/satiety/score",
});

export const SatietyPage = createExternalPage({
  name: "satiety",
  path: () => "/satiety",
});

export const FoodExplorerPage = createRegularPage<undefined, { mode: FoodExplorerMode; tag?: string }>({
  name: "foodExplorer.food",
  component: path.resolve("./src/ecosystems/food-explorer/FoodExplorerPage.tsx"),
  path: (locale, { mode, tag }) =>
    tag
      ? `${localePrefix[locale]}/food-navigator/${mode}/${tag}`
      : `${localePrefix[locale]}/food-navigator/${mode}`,
});

export const FoodExplorerFoodPage = createRegularPage<{ item: FoodExplorerFoodItem }, { slug: string }>({
  name: "foodExplorer.food",
  component: path.resolve("./src/ecosystems/food-explorer/FoodExplorerFoodPage.tsx"),
  path: (locale, { slug }) => `${localePrefix[locale]}/food/${slug}`,
});

const doctorPages = {
  [DoctorsPage.name]: DoctorsPage,
  [AllDoctorsPage.name]: AllDoctorsPage,
  [DoctorProfilePage.name]: DoctorProfilePage,
};

export const pages: Record<string, InternalPage<any, any, any>> = {
  ...doctorPages,
  [ShoppingListPage.name]: ShoppingListPage,
  [DDProPage.name]: DDProPage,
  [DDProAboutPage.name]: DDProAboutPage,
  [DDProAddPage.name]: DDProAddPage,
  [DDProPDFPage.name]: DDProPDFPage,
  [DDProJoinPage.name]: DDProJoinPage,
  [DDProJoinExistingPage.name]: DDProJoinExistingPage,
  [DDProJoinCheckExistingPage.name]: DDProJoinCheckExistingPage,
  [DDProJoinConfirmExistingPage.name]: DDProJoinConfirmExistingPage,
  [MealPlanPage.name]: MealPlanPage,
  [MyMealPlansPage.name]: MyMealPlansPage,
  [LoginPage.name]: LoginPage,
  [WelcomePage.name]: WelcomePage,
  [NewMemberPersonalizationPage.name]: NewMemberPersonalizationPage,
  [QuizLandingPage.name]: QuizLandingPage,
  [QuizLandingPageAlias.name]: QuizLandingPageAlias,
  [QuizLandingPageSecondAlias.name]: QuizLandingPageSecondAlias,
  [PersonalizedPlanProjectionPage.name]: PersonalizedPlanProjectionPage,
  [PersonalizedPlanProjectionPagev2.name]: PersonalizedPlanProjectionPagev2,
  [PersonalizedPlanSalesPage.name]: PersonalizedPlanSalesPage,
  [PersonalizedPlanSalesPagev2.name]: PersonalizedPlanSalesPagev2,
  [NotFoundPage.name]: NotFoundPage,
  [SearchPage.name]: SearchPage,
  [TrackingPage.name]: TrackingPage,
  [LoggedOutHomePage.name]: LoggedOutHomePage,
  [LoggedOutHomePageAB.name]: LoggedOutHomePageAB,
  [LoggedInHomePage.name]: LoggedInHomePage,
  [BrazePreferenceCenterRedirectPage.name]: BrazePreferenceCenterRedirectPage,
  [NewMemberPage.name]: NewMemberPage,
  [NewMemberPagePodcast.name]: NewMemberPagePodcast,
  [NewMemberPageCommunity.name]: NewMemberPageCommunity,
  [NewMemberPageGuide.name]: NewMemberPageGuide,
  [NewMemberPageProgram.name]: NewMemberPageProgram,
  [NewMemberPageVideo.name]: NewMemberPageVideo,
  [NewMemberPageMealPlan.name]: NewMemberPageMealPlan,
  [NewMemberPageFreeTrial.name]: NewMemberPageFreeTrial,
  [UserPersonalSettingsPage.name]: UserPersonalSettingsPage,
  [UserFoodPreferencesPage.name]: UserFoodPreferencesPage,
  [FoodExplorerPage.name]: FoodExplorerPage,
  [FoodExplorerFoodPage.name]: FoodExplorerFoodPage,
};
