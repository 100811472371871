import {
  ApolloClient,
  DocumentNode,
  InMemoryCache,
  NormalizedCacheObject,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  createHttpLink,
  useQuery as useQueryApollo,
} from "@apollo/client";
import React, { createContext, ReactNode, useContext } from "react";
import { setContext } from "@apollo/client/link/context";

export function useDirectus() {
  const [client] = useContext(DirectusContext);

  const useQuery = <TData, TVariables extends OperationVariables = OperationVariables>(
    query: DocumentNode | TypedDocumentNode<TData, TVariables>,
    options?: QueryHookOptions<TData, TVariables>
  ) => useQueryApollo(query, { client: client, ...options });

  return { client, useQuery };
}

const DirectusContext = createContext([] as unknown as [ApolloClient<NormalizedCacheObject>]);

const httpLink = createHttpLink({
  uri: process.env.GATSBY_DIRECTUS_API_URL!,
  credentials: "include",
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      Authorization: `Bearer ${process.env.GATSBY_DIRECTUS_API_TOKEN}`,
    },
  };
});

export const Directus = ({ children }: { children: ReactNode }) => {
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return <DirectusContext.Provider value={[client]}>{children}</DirectusContext.Provider>;
};
