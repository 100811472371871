import { useApolloClient, ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { useState, useCallback, useEffect } from "react";
import Cookies from "js-cookie";
import { Auth } from "../../types/Auth";
import { GetEssentialUserInfo } from "../../types/GetEssentialUserInfo";
import GetEssentialUserInfoQuery from "./GetEssentialUserInfo.graphql";
import GetBrazeSDKAuthTokenQuery from "../GetBrazeSDKAuthToken.graphql";
import * as AuthUtils from "../../utils/auth";
import { pushGtmVariables } from "../../utils/gtm";
import { auth } from "../../utils/auth";
import { isBrowser } from "../../utils/ssr";
import { GetBrazeSDKAuthToken } from "../../types/GetBrazeSDKAuthToken";
import { setExternalId } from "../../utils/braze";
import { signInWithCustomToken, signOut } from "@firebase/auth";
import { onAuthStateChanged } from "firebase/auth";
import { User, Unsubscribe } from "firebase/auth";
import { MembershipSubscriptionStatus } from "../../types/graphql-global-types";
import { isServerParseError } from "../../utils/error";

// This function fetches the latest user info data from the DD-API
const fetchUserInfo = async (client: ApolloClient<NormalizedCacheObject>): Promise<GetEssentialUserInfo> => {
  try {
    const userInfoResponse = await client.query<GetEssentialUserInfo>({
      query: GetEssentialUserInfoQuery,
    });
    return userInfoResponse.data;
  } catch (error: any) {
    if (
      error.networkError &&
      isServerParseError(error.networkError) &&
      error.networkError.bodyText.includes("the email is already registered")
    ) {
      throw new Error("the email is already registered");
    } else {
      throw error;
    }
  }
};

const getBrazeSDKAuthToken = async (
  client: ApolloClient<NormalizedCacheObject>
): Promise<GetBrazeSDKAuthToken> => {
  try {
    const tokenResponse = await client.query<GetBrazeSDKAuthToken>({
      query: GetBrazeSDKAuthTokenQuery,
      variables: { input: { z: true } },
    });
    return tokenResponse.data;
  } catch (error) {
    console.log("Failed getting response from GetBrazeSDKAuthToken: " + error);
    throw error;
  }
};

function useAuth(): Auth {
  const [autoLogin, setAutoLogin] = useState<boolean>(true);

  const [userInfo, setUserInfo] = useState(AuthUtils.getLocalUserInfo());
  const user = userInfo?.me;
  const subscription = userInfo?.membershipSubscription || undefined;

  const [firebaseLoggedIn, setFirebaseLoggedIn] = useState(false);
  const [firebaseUserId, setFirebaseUserId] = useState<string | undefined>();

  const [loggedIn, setLoggedIn] = useState(false);
  const premium = loggedIn && Boolean(AuthUtils.isSubscriptionActive(userInfo));

  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | undefined>();

  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

  // Function that fetch latest firebase token and saves it in cookie
  const refreshFirebaseToken = useCallback(async () => {
    // Fetch and cache JWT
    const firebaseToken = await AuthUtils.fetchAndCacheTokenUsingFirebase(true);
    if (!firebaseToken) {
      throw new Error("Firebase token fetch failed");
    }

    AuthUtils.setAccessTokenCookie(firebaseToken);
  }, []);

  // Logout and remove user related data from local storage and cookies
  const logout = useCallback(async () => {
    setAutoLogin(false);
    Cookies.remove("auth", {
      domain: isBrowser() ? `${window.location.hostname}` : "dietdoctor.com",
    });
    await signOut(auth);
    AuthUtils.clearLocalUserData();
    client.resetStore();
    setUserInfo(null);
    setLoggedIn(false);

    pushGtmVariables({ userId: undefined });
  }, [client, setUserInfo, setAutoLogin]);

  // Login with password, currently only used when logging in locally directly from gatsby
  const loginWithPassword = useCallback(
    async (username: string, password: string, remember?: boolean) => {
      try {
        setLoading(true);

        // Make sure user is logged out
        await logout();

        //Remember me
        localStorage.setItem("REMEMBER_ME", remember ? "true" : "false");

        // Get a custom token from DDAPI
        const customToken = await AuthUtils.fetchCustomToken(username, password);

        // Use custom token to sign in on firebase
        const credentials = await signInWithCustomToken(auth, customToken);

        if (!credentials.user) {
          // TODO: What could cause this?
          throw new Error("Empty user returned");
        }

        setError(undefined);
      } catch (e: any) {
        await logout();
        setError(e);
        throw new Error(e);
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError, logout]
  );

  // Fetch user info, saves it to local storage then updates GTM userId variable
  const updateUserInfo = useCallback(async () => {
    // Store user details in local storage
    try {
      const latestUserInfo = await fetchUserInfo(client);

      const isPremiumUser =
        latestUserInfo.membershipSubscription?.status === MembershipSubscriptionStatus.ACTIVE;

      if (latestUserInfo) {
        AuthUtils.saveUserInfo(latestUserInfo);
        setUserInfo(latestUserInfo);

        // Send the user ID to GTM.
        pushGtmVariables({ userId: latestUserInfo.me.userId });
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: "start_pw",
          pw_user_email: latestUserInfo.me.email,
        });

        if (isPremiumUser) {
          const brazeSDKtoken = await getBrazeSDKAuthToken(client);
          window.dataLayer.push({
            event: "braze_init",
            isPremiumUser: isPremiumUser,
          });

          setExternalId({
            userId: latestUserInfo.me.userId,
            authToken: brazeSDKtoken.getBrazeSDKAuthToken.token,
          });
        }
      }
    } catch (error: any) {
      await logout();
      setError(error);
    }
  }, [client, setUserInfo, logout]);

  // First load
  useEffect(() => {
    const authCookieAccessToken = Cookies.get("auth");
    if (authCookieAccessToken && AuthUtils.isValidToken(authCookieAccessToken)) {
      setLoggedIn(true);
    }

    // Triggered on sign-in or sign-out
    const onFirebaseAuthStateChanged = async (user: User | null) => {
      try {
        const authCookieAccessToken = Cookies.get("auth");

        if (user && user.providerData.length !== 0 && user.email === null) {
          await logout();
        } else if (user) {
          setFirebaseLoggedIn(true);
          setFirebaseUserId(user.uid);

          // Enable periodic access token refresh
          setInterval(async () => {
            refreshFirebaseToken();
          }, 2700000); // 45 minutes firebase access token refresh (1 hr expiration)

          if (autoLogin) {
            // Check if access token is older than 15 min, then refresh access token
            if (authCookieAccessToken && AuthUtils.isTokenNeedRefresh(authCookieAccessToken)) {
              await refreshFirebaseToken();
            } else {
              const firebaseAccessToken = await user.getIdToken();
              // Set cookie token without Firebase refresh in order to reduce calls
              if (firebaseAccessToken && AuthUtils.isValidToken(firebaseAccessToken)) {
                AuthUtils.setAccessTokenCookie(firebaseAccessToken);
              }
            }
            // Make sure we have latest user info so there is no stale state, especially important for membership status
            await updateUserInfo();
          }
          // No auto login and token is invalid
          else if (
            !authCookieAccessToken ||
            (authCookieAccessToken && !AuthUtils.isValidToken(authCookieAccessToken))
          ) {
            await logout();
          }
        } else if (authCookieAccessToken && AuthUtils.isValidToken(authCookieAccessToken) && autoLogin) {
          await updateUserInfo();
          AuthUtils.saveTokenLocally(authCookieAccessToken);
        }
      } catch (e) {
        // Do nothing. Swallow this exception and do not log in the user.
      } finally {
        setLoading(false);
      }
    };

    let firebaseCleanup: Unsubscribe | undefined = undefined;
    if (isBrowser()) {
      firebaseCleanup = onAuthStateChanged(auth, onFirebaseAuthStateChanged);
    }
    return () => {
      firebaseCleanup?.();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Only on initial load

  // We're only fully logged in once we have a Firebase user (so we can extract the JWT
  // from it) and when we have user info from DDAPI.
  useEffect(() => {
    if (Boolean(firebaseLoggedIn) && Boolean(user)) {
      setLoggedIn(true);
    }
  }, [firebaseLoggedIn, user]);

  return {
    user,
    firebaseUserId,
    loggedIn,
    premium,
    subscription,
    loading,
    error,
    loginWithPassword,
    logout,
  };
}

export default useAuth;
