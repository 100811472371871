import { ApolloError, ServerParseError } from "@apollo/client";

export const isError = (object: any): object is Error => {
  return object && object.stack && object.message;
};

export const isServerParseError = (error: any): error is ServerParseError => {
  if (!error) {
    return false;
  }
  return Object.prototype.hasOwnProperty.call(error, "bodyText");
};

export const isApolloError = (error: any): error is ApolloError => {
  if (!error) {
    return false;
  }
  return (
    Object.prototype.hasOwnProperty.call(error, "graphQLErrors") ||
    Object.prototype.hasOwnProperty.call(error, "networkError")
  );
};

export const isTokenExpiredError = (error: any) => {
  let message = "";
  if (isApolloError(error) && isServerParseError(error.networkError)) {
    message = error.networkError.bodyText;
  } else if (isServerParseError(error)) {
    message = error.bodyText;
  }

  return message.indexOf("Token is expired") >= 0 || message.indexOf("Token error") >= 0;
};
