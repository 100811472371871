import * as path from "path";
import { createClientSidePage, createRegularPage } from "../../page-utils";
import { localePrefix } from "../../pages";
import { Locale } from "../../types/Locale";

export const EmptyMealPlanPage = createRegularPage({
  name: "mealplans.empty",
  component: path.resolve("./src/components/MealPlannerPage/MealPlannerPage.tsx"),
  path: (locale) => {
    switch (locale) {
      case Locale.ES:
        return `${localePrefix[locale]}/menu`;
      case Locale.SV:
        return `${localePrefix[locale]}/veckomeny`;
      default:
        return `${localePrefix[locale]}/meal-plan`;
    }
  },
});

export const MealPlanPage = createClientSidePage<undefined, MealPlanPageParams, MealPlanPageQuery>({
  name: "mealplans.mealplan",
  component: path.resolve("./src/ecosystems/mealplans/mealplan/MealPlanPage.tsx"),
  path: (locale, { slug }, { edit } = {}) => {
    const editPart = edit ? `?edit=true` : "";
    switch (locale) {
      case Locale.ES:
        return `${localePrefix[locale]}/menu/${slug}${editPart}`;
      case Locale.SV:
        return `${localePrefix[locale]}/veckomeny/${slug}${editPart}`;
      default:
        return `${localePrefix[locale]}/meal-plan/${slug}${editPart}`;
    }
  },

  matchPath: (locale) => {
    switch (locale) {
      case Locale.ES:
        return `${localePrefix[locale]}/menu/*`;
      case Locale.SV:
        return `${localePrefix[locale]}/veckomeny/*`;
      default:
        return `${localePrefix[locale]}/meal-plan/*`;
    }
  },
  routerPaths: (locale) => {
    switch (locale) {
      case Locale.ES:
        return [`${localePrefix[locale]}/menu/:slug/*`];
      case Locale.SV:
        return [`${localePrefix[locale]}/veckomeny/:slug/*`];
      default:
        return [`${localePrefix[locale]}/meal-plan/:slug/*`];
    }
  },
  processParamsAndQuery: (locale, _, { slug }, rawQuery) => {
    const params: MealPlanPageParams = {
      slug: slug || "",
    };
    const query: MealPlanPageQuery = {
      edit: rawQuery.edit === "true" || false,
    };
    return [params, query];
  },
});

interface MealPlanPageParams {
  slug: string;
}

interface MealPlanPageQuery {
  edit?: boolean;
}
