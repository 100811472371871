import { isLocalDevelopment } from "./environment";
import { isBrowser } from "./ssr";

export function sendEvent({
  eventName,
  action,
  payload,
}: {
  eventName: string;
  action: string;
  payload?: Record<string, any>;
}) {
  if (process.env.NODE_ENV === "production") {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "GA4_EVENT",
      event_name: eventName,
      event_action: action,
      event_payload: payload && JSON.stringify(payload), // Pass the payload stringified in order to make it parsable by Big Query.
    });
  } else {
    console.info(`Firing GA4 event`, eventName, action);
  }
}

export const pushGtmVariables = (variables: RecordParameter = {}) => {
  if (isBrowser()) {
    if (isLocalDevelopment()) {
      console.info(`Firing GTM event`, variables);
    }

    if (process.env.NODE_ENV === "production") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(variables);
    }
  }
};

type ScalarParameter = string | number | boolean | undefined;
// https://stackoverflow.com/a/45999529/563154
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface RecordParameter extends Record<string, ScalarParameter | RecordParameter> {}
