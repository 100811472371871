import { ILang, Nav, OnAnalyticsRequestCallbackType, OnLogoutRequestCallbackType } from "@dietdoctor/elements";
import "@dietdoctor/elements/build/style.css";
import React, { useCallback, useContext } from "react";
import { useUser } from "../../hooks/useUser/useUser";
import { LoginPage } from "../../pages";
import { sendEvent } from "../../utils/gtm";
import { useNavigate } from "../Link/Link";
import { pageContext } from "../PageProvider/PageProvider";

const NavigationBar: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { locale } = useContext(pageContext);
  const { user, send } = useUser(() =>
    navigate({
      to: LoginPage,
      query: {
        redirectTo: document.location.href,
      },
    })
  );

  const onLogoutRequestCallback: OnLogoutRequestCallbackType = useCallback(async () => {
    send("LOG_OUT");
  }, [send]);

  const onAnalyticsRequestCallback: OnAnalyticsRequestCallbackType = useCallback((label: string) => {
    sendEvent({
      eventName: "navbar",
      action: "Click",
      payload: { label },
    });
  }, []);

  const lang = locale as unknown as ILang;

  return (
    <div id="header" className="h-14 print:hidden">
      <Nav
        lang={lang}
        user={user}
        onLogoutRequestCallback={onLogoutRequestCallback}
        onAnalyticsRequestCallback={onAnalyticsRequestCallback}
      />
    </div>
  );
};

export { NavigationBar };
