import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import fromUnixTime from "date-fns/fromUnixTime";
import isFuture from "date-fns/isFuture";
import { sha256 } from "js-sha256";
import { GetEssentialUserInfo } from "../types/GetEssentialUserInfo";
import { MembershipSubscriptionStatus } from "../types/graphql-global-types";
import {
  browserLocalPersistence,
  browserSessionPersistence,
  indexedDBLocalPersistence,
  initializeAuth,
} from "firebase/auth";
import firebaseApp from "./firebase";
import { isBrowser } from "./ssr";

/* This constant is only exported for testing purposes. It should not be used directly. */
export const USER_INFO_LOCAL_KEY = "DIETDOCTOR_USER_INFO";
/* This constant is only exported for testing purposes. It should not be used directly. */
export const JWT_TOKEN_LOCAL_KEY = "DIETDOCTOR_TOKEN";
/**
 * This value is duplicated in other parts of the project (e.g. src/gatsby/gatsby-config.ts).
 * !!! SEARCH THE CODEBASE FOR THIS VALUE BEFORE MODIFYING IT !!!
 *
 * This constant is only exported for testing purposes. It should not be used directly.
 */
export const USERID_LOCAL_KEY = "DIETDOCTOR_USERID";

const clientStorage = (): Storage => window.localStorage;

interface JWTToken {
  iss?: string;
  iat: number;
  nbf?: number;
  exp: number;
  data?: {
    user?: {
      id?: string;
      uid?: string;
      mex?: number;
      type?: string;
    };
  };
  legacy_user_id?: string;
}

/* Get firebase auth */
export const auth = initializeAuth(firebaseApp, {
  persistence: [indexedDBLocalPersistence, browserLocalPersistence, browserSessionPersistence],
});

/**
 * Get user id from local user info or, if not available, from local JWT.
 * This function is used maily to decorate logs.
 */
export const getLocalUserId = () => {
  const userInfo = getLocalUserInfo();
  if (userInfo) {
    return userInfo.me.userId;
  }
  const token = clientStorage()?.getItem(JWT_TOKEN_LOCAL_KEY);
  if (!token) {
    return undefined;
  }
  try {
    const decoded = jwt_decode(token) as JWTToken;
    return decoded?.data?.user?.uid;
  } catch (error) {
    return undefined;
  }
};

// Retrieve user info data that is stored locally and parse it to JSON
export const getLocalUserInfo = (): GetEssentialUserInfo | null => {
  if (!isBrowser()) {
    return null;
  }
  const userInfoData = clientStorage()?.getItem(USER_INFO_LOCAL_KEY);
  return userInfoData ? JSON.parse(userInfoData) : null;
};

// Read token that is stored locally.
const getLocalToken = (): string | null => {
  if (!isBrowser()) {
    return null;
  }

  if (Cookies.get("auth")) {
    return Cookies.get("auth") || null;
  }

  return null;
};

/**
 * Returns a valid (not expired) JSON Web Token (JWT) for DDAPI. The retrieved token will be cached locally.
 * Returns the current token if it has not expired. Otherwise, this will refresh the token and return a new one.
 * @returns null if there is no logged user, token otherwise.
 * @throws if the token fetching fails.
 */
export const fetchAndCacheTokenUsingFirebase = async (forceRefresh?: boolean): Promise<string | null> => {
  if (!isBrowser()) {
    return null;
  }
  const user = auth.currentUser;
  if (!user) {
    return null;
  }
  const token = await user.getIdToken(forceRefresh);
  saveTokenLocally(token);
  return token;
};

/**
 * Returns a JSON Web Token (JWT) for DDAPI.
 * Return a cached token if it exists, fetch and cache a new one otherwise.
 * @returns undefined if there is no logged user, token otherwise.
 * @throws if the token fetching fails.
 */
export const getLocalTokenOrFetchNewOne = async (): Promise<string | null> => {
  if (!isBrowser()) {
    return null;
  }
  const local = getLocalToken();
  if (local) {
    const decoded: JWTToken = jwt_decode(local);
    const exp = fromUnixTime(decoded.exp);
    if (isFuture(exp)) {
      return local;
    }
  }
  return await fetchAndCacheTokenUsingFirebase();
};

// Store access token to localStorage
export const saveTokenLocally = (token: string) => {
  clientStorage()?.setItem(JWT_TOKEN_LOCAL_KEY, token);
};

// Store access token to cookie
export const setAccessTokenCookie = (token: string) => {
  // Add token to cookies for SSO
  Cookies.set("auth", token, {
    domain: isBrowser() ? `${window.location.hostname}` : "dietdoctor.com",
    secure: isBrowser() && "https:" === window.location.protocol,
    expires: localStorage.getItem("REMEMBER_ME") == "false" ? undefined : 365,
  });
};

// Store user data locally (to keep user logged in between page refreshes)
export const saveUserInfo = (userInfo: GetEssentialUserInfo) => {
  clientStorage()?.setItem(USER_INFO_LOCAL_KEY, JSON.stringify(userInfo));
  clientStorage()?.setItem(USERID_LOCAL_KEY, sha256(userInfo.me.userId));
};

// Erase all user information stored locally
export const clearLocalUserData = () => {
  clientStorage()?.removeItem(USER_INFO_LOCAL_KEY);
  clientStorage()?.removeItem(USERID_LOCAL_KEY);
  clientStorage()?.removeItem(JWT_TOKEN_LOCAL_KEY);
  clientStorage()?.removeItem("REMEMBER_ME");
};

// Return a custom token for Firebase authentication.
export const fetchCustomToken = async (username: string, password: string): Promise<string> => {
  const requestOptions = {
    body: JSON.stringify({ username, password }),
    method: "POST",
  };
  const response = await fetch(`${process.env.GATSBY_API_URL}/auth/token`, requestOptions);
  return handleFetchTokenResponse(response);
};

// Check if correct response
const handleFetchTokenResponse = async (response: Response) => {
  if (!response.ok) {
    const responseText = await response.text();
    throw new Error(`Error fetching token: ${responseText}`);
  }

  const data = await response.json();

  if (!data.token) {
    throw new Error(`No token returned: ${JSON.stringify(data)}`);
  }
  return data.token;
};

// Check if user have active subscription

// used to allow or disallow access to member features and content
export const isSubscriptionActive = (userInfo: GetEssentialUserInfo | null): boolean =>
  MembershipSubscriptionStatus.ACTIVE === userInfo?.membershipSubscription?.status;

// If it has passed 15 minutes since the token was issued
export const isTokenNeedRefresh = (token: string) => {
  if (!token) {
    return false;
  }
  const decodedToken: JWTToken = jwt_decode(token);
  return Date.now().valueOf() / 1000 - decodedToken.iat > 900;
};

// Check if jwt token is valid
export const isValidToken = (token: string) => {
  if (!token) {
    return false;
  }
  const decodedToken: JWTToken = jwt_decode(token);
  return Date.now().valueOf() / 1000 < decodedToken.exp;
};
