import { NavigateOptions } from "@reach/router";
import { GatsbyLinkProps, Link as GatsbyLink, navigate } from "gatsby";
import React, { PropsWithChildren, useCallback, useContext } from "react";
import { Page } from "../../pages";
import { Locale } from "../../types/Locale";
import { pageContext } from "../PageProvider/PageProvider";

export function Link<Params, Query>(props: LinkProps<Params, Query>) {
  const { children, className, innerRef, to: page, ...extraProps } = props;

  const link = useLink();
  const href = link(props);

  if (page.type === "external") {
    return (
      <a href={href} className={className} ref={innerRef} {...extraProps}>
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink to={href} className={className} innerRef={innerRef} {...extraProps}>
      {children}
    </GatsbyLink>
  );
}

type LinkProps<Params, Query, State = undefined> = PropsWithChildren<
  Pick<GatsbyLinkProps<State>, "innerRef" | "onClick" | "className" | "target" | "rel" | "style" | "replace"> &
    UseLinkArgs<Params, Query>
>;

export function useNavigate() {
  const { locale } = useContext(pageContext);

  return useCallback(
    <Params, Query, State>(args: UseNavigateArgs<Params, Query, State>) => {
      const { replace, state, to: page } = args;
      const path = getLinkPath(locale, args);

      if (page.type === "external") {
        if (replace) {
          location.replace(path);
        } else {
          location.href = path;
        }
      } else {
        // @ts-ignore
        navigate(path, { replace, state });
      }
    },
    [locale]
  );
}

type UseNavigateArgs<Params, Query, State = undefined> = UseLinkArgs<Params, Query> & NavigateOptions<State>;

export type NavigateFn = ReturnType<typeof useNavigate>;

export function useLink() {
  const { locale } = useContext(pageContext);

  return <Params, Query>(args: UseLinkArgs<Params, Query>) => {
    return getLinkPath(locale, args);
  };
}

export type LinkFn = ReturnType<typeof useLink>;

type UseLinkArgs<Params, Query> = {
  to: Page<unknown, Params, Query>;
  locale?: Locale;
  full?: boolean;
} & ParamsSlice<Params> &
  QuerySlice<Query>;

/* eslint-disable @typescript-eslint/ban-types */
type ParamsSlice<Params> = Params extends undefined ? {} : { params: Params };
type QuerySlice<Query> = Query extends undefined ? {} : { query?: Query };
/* eslint-enable @typescript-eslint/ban-types */

function getLinkPath<Params, Query>(pageLocale: Locale, args: UseLinkArgs<Params, Query>): string {
  const locale = args.locale ?? pageLocale;
  const params = (args as any).params ?? undefined; // XXX
  const query = (args as any).query ?? undefined; // XXX
  const path = args.to.path(locale, params, query);

  if (args.to.type === "external" || args.full) {
    return getAbsolutePath(path);
  }

  return path;
}

function getAbsolutePath(path: string): string {
  const host = process.env.GATSBY_WEBSITE_URL || "";
  return host + path;
}
