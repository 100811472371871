import React from "react";
import styled from "@emotion/styled";
import { NavigationBar } from "../NavBar/NavigationBar";

const Main = styled.main`
  min-height: 70vh;
`;

export const NoFooter: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => (
  <>
    <NavigationBar />
    <Main>{children}</Main>
  </>
);
