import { select, t } from "@lingui/macro";
import Locale from "date-fns/locale";
import format from "date-fns/format";
import { enUS as dateFnsEnLocale, es as dateFnsEsLocale, sv as dateFnsSvLocale } from "date-fns/locale";
import parse from "date-fns/parse";
import { MealType } from "../types/graphql-global-types";
import { Locale as LocaleType } from "../types/Locale";
import { DayKey } from "../types/MealPlan";
import { upperFirst } from "./string";

export function mealLabel(type: MealType, capitalize = false): string {
  const label = t({
    id: "common.meals.mealLabel",
    message: select(type, {
      BREAKFAST: "breakfast",
      LUNCH: "lunch",
      DINNER: "dinner",
    }),
  });
  return capitalize ? upperFirst(label) : label;
}

export function mealDifficulty(difficulty: string, capitalize = false): string {
  const mealDifficulty = t({
    id: "common.meals.difficulty",
    message: select(difficulty, {
      easy: "easy",
      medium: "medium",
      beginner: "beginner",
      hard: "hard",
    }),
  });
  return capitalize ? upperFirst(mealDifficulty) : mealDifficulty;
}

export function ingredientOr() {
  return t({
    id: "common.ingredients.or",
    message: " or ",
  });
}

export function ingredientAnd() {
  return t({
    id: "common.ingredients.and",
    message: " and ",
  });
}

export function ingredientOptional() {
  return t({
    id: "common.ingredients.optional",
    message: "optional",
  });
}

export function dateLong(locale: LocaleType) {
  return (date: string) => {
    return new Date(date).toLocaleDateString(locale, {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };
}

export function weekdayShort(locale: LocaleType) {
  return (day: DayKey) => {
    const date = parse(day.replace(/\d+/g, ""), "EEEE", baseDate);
    return format(date, "E", { locale: dateFnsLocales[locale] });
  };
}

export function weekdayLong(locale: LocaleType) {
  return (day: DayKey) => {
    const date = parse(day.replace(/\d+/g, ""), "EEEE", baseDate);
    const text = format(date, "EEEE", { locale: dateFnsLocales[locale] });
    return upperFirst(text);
  };
}

const dateFnsLocales: Record<LocaleType, Locale> = {
  [LocaleType.EN]: dateFnsEnLocale,
  [LocaleType.ES]: dateFnsEsLocale,
  [LocaleType.SV]: dateFnsSvLocale,
  [LocaleType.ZU]: dateFnsEnLocale,
};

const baseDate = new Date();
