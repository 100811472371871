import classnames from "classnames";
import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { MOBILE_LG } from "../../utils/breakpoints";
import { default as colors } from "../../utils/colors.json";
import { LoadingIcon } from "../Icon/Icon";

type ButtonTypeVariant = "contained" | "outlined";
type ColorVariant = "blue" | "pink" | "green" | "grey" | "gold" | "darkpink" | "black";
type SizeVariant = "small";

interface ButtonColors {
  background: string;
  border: string;
  foreground: string;
  backgroundHover: string;
  borderHover: string;
}

const COLOR_VARIANTS: { [key in ColorVariant]: ButtonColors } = {
  blue: {
    background: colors.BLUE,
    border: colors.BLUE,
    foreground: colors.WHITE,
    backgroundHover: colors.BLUE_LIGHT,
    borderHover: colors.BLUE_LIGHT,
  },
  pink: {
    background: colors.PINK,
    border: colors.PINK,
    foreground: colors.WHITE,
    backgroundHover: colors.PINK_LIGHT,
    borderHover: colors.PINK_LIGHT,
  },
  green: {
    background: colors.GREEN,
    border: colors.GREEN,
    foreground: colors.WHITE,
    backgroundHover: colors.GREEN_LIGHT,
    borderHover: colors.GREEN_LIGHT,
  },
  grey: {
    background: colors.GREY,
    border: colors.GREY,
    foreground: colors.WHITE,
    backgroundHover: colors.GREY_LIGHT,
    borderHover: colors.GREY_LIGHT,
  },
  gold: {
    background: colors.GOLD,
    border: colors.GOLD,
    foreground: colors.GREY_DARK,
    backgroundHover: colors.GOLD,
    borderHover: colors.GOLD,
  },
  darkpink: {
    background: colors.DARK_PINK,
    border: colors.DARK_PINK,
    foreground: colors.WHITE,
    backgroundHover: colors.PINK,
    borderHover: colors.PINK,
  },
  black: {
    background: colors.BLACK,
    border: colors.BLACK,
    foreground: colors.WHITE,
    backgroundHover: colors.GREY_DARKER,
    borderHover: colors.GREY_DARKER,
  },
};

const getColorVariants = (colorVariant: ColorVariant, invert: boolean) => {
  const colors = COLOR_VARIANTS[colorVariant];
  return invert
    ? {
        background: colors.backgroundHover,
        border: colors.borderHover,
        foreground: colors.foreground,
        backgroundHover: colors.background,
        borderHover: colors.border,
      }
    : colors;
};

const BaseButton = styled.button<ButtonColors>`
  position: relative;
  display: inline-block !important;
  width: 100% !important;
  cursor: pointer;
  min-height: 50px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2222222222;
  background-color: ${({ background }) => background} !important;
  color: ${({ foreground }) => foreground} !important;
  border: 2px solid ${({ border }) => border} !important;
  text-align: center;
  border-radius: 35px;
  padding: 12px 25px;

  &.button--small {
    min-height: 40px;
    padding: 7px 25px;
  }

  &.button--disabled,
  &.button--loading {
    cursor: default;
    &:hover {
      background-color: ${({ background }) => background};
      border-color: ${({ border }) => border};
      color: ${({ foreground }) => foreground};
    }
    opacity: 0.35;
  }

  &.button--disabled {
    cursor: not-allowed;
  }

  &.button--loading {
    cursor: progress;
  }

  .button__label {
    display: flex;
    justify-content: center;
  }

  .button__icon {
    height: 22px;
    margin-right: 5px;
  }

  &.button--loading > .button__label {
    color: transparent;
  }

  @media (min-width: ${MOBILE_LG}) {
    display: inline-block !important;
    width: auto !important;
  }

  &:hover {
    background-color: ${({ backgroundHover }) => backgroundHover} !important;
    border-color: ${({ borderHover }) => borderHover} !important;
  }
`;

const OutlinedButton = styled(BaseButton)`
  background-color: transparent;
  color: ${({ border }) => border} !important;

  &:hover {
    background-color: transparent !important;
    color: ${({ borderHover }) => borderHover} !important;
  }

  &.button--disabled,
  &.button--loading {
    cursor: default;
    &:hover {
      background-color: transparent !important;
      border-color: ${({ border }) => border} !important;
      color: ${({ border }) => border} !important;
    }
  }
`;

const CenteredLoadingIcon = styled(LoadingIcon)`
  height: 22px;
  width: 22px;
  position: absolute;
  left: 50%;
  margin-left: -10px;
`;

type HTMLButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
type BaseButtonType = Omit<HTMLButtonProps, "ref">;
type ButtonProps = {
  variant?: ButtonTypeVariant;
  size?: SizeVariant;
  color?: ColorVariant;
  invertColors?: boolean;
  disabled?: boolean;
  type?: "button" | "submit";
  loading?: boolean;
  icon?: ReactNode;
  dataTestid?: string;
} & BaseButtonType;

const Button = ({
  variant = "contained",
  color = "green",
  size,
  invertColors = false,
  loading = false,
  disabled = false,
  type = "button",
  icon,
  onClick,
  className,
  children,
  dataTestid,
  ...rest
}: ButtonProps) => {
  const colors = getColorVariants(color, invertColors);
  const ButtonVariant = variant === "outlined" ? OutlinedButton : BaseButton;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (onClick && !disabled && !loading) {
      onClick(event);
    }
  };
  return (
    <ButtonVariant
      {...colors}
      {...rest}
      onClick={handleClick}
      type={type}
      disabled={disabled}
      className={classnames(className, {
        "button--disabled": disabled || loading,
        "button--loading": loading,
        "button--small": size === "small",
      })}
      data-testid={dataTestid}
    >
      {loading && <CenteredLoadingIcon />}
      <span className="button__label">
        {icon && <span className="button__icon">{icon}</span>}
        {children}
      </span>
    </ButtonVariant>
  );
};

export default React.memo(Button);
