import React from "react";
import { NavigationBar } from "../NavBar/NavigationBar";
import Footer from "../Footer/Footer";

export const DefaultLayout: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => (
  <>
    <NavigationBar />
    <main className="min-h-[70vh]">{children}</main>
    <Footer />
  </>
);
