import { captureException, captureMessage, SeverityLevel, withScope } from "@sentry/gatsby";
import { getLocalUserId, getLocalUserInfo } from "./auth";
import { isError } from "./error";
import { isBrowser } from "./ssr";

export const logError = (
  message: string,
  error?: Error,
  context: {
    tags?: { [key: string]: string };
    extras?: { [key: string]: any };
    fingerprint?: string[];
    level?: SeverityLevel;
  } = {}
) => {
  if (!isBrowser()) {
    return console.error(message, error, context);
  }

  const { tags, extras, fingerprint, level = "error" } = context;
  withScope((scope) => {
    scope.setUser({
      id: getLocalUserId(),
      email: getLocalUserInfo()?.me.email,
    });
    scope.setTags({
      ...(tags || {}),
      version: process.env.GATSBY_APP_VERSION || "unknown",
    });
    scope.setExtras({
      ...(extras || {}),
      message,
    });
    if (fingerprint) {
      scope.setFingerprint(fingerprint);
    }

    if (isError(error)) {
      if (shouldIgnoreError(error)) {
        // don't log
        return;
      }
      captureException(error);
    } else {
      captureMessage(message, level);
    }
  });
};

function shouldIgnoreError(error?: Error) {
  if (error && error.message.indexOf("We couldn't load") >= 0) {
    // This error normally occurs after a deployment,
    // the previous non existing (cached) file is called.
    // i.e: `We couldn't load "${__PATH_PREFIX__}/page-data/sq/d/${staticQueryHash}.json"`
    return true;
  }
  return false;
}
