import { defaultLocale, Locale } from "../types/Locale";

/**
 * This contains the application's current locale.
 *
 * The application locale is defined by the current page context. The page context is read by
 * the `PageProvider` component and made available downstream via the `pageContext` React context.
 *
 * Apollo needs to know the locale to be able to select the correct HTTP link. One way to do it
 * would be to send the locale as a piece of context to every GraphQL query and mutation, but this
 * would entail having to customize every call to an Apollo hook. And the locale itself wouldn't be
 * available upstream anyway.
 *
 * The approach here is to always set the locale everytime it changes. And the one who does that
 * is the owner of that information: `PageProvider`.
 *
 * The second half of this approach is to have a custom `ApolloLink` that reads the locale from
 * this module for every operation and switch backends based on its value.
 */
let locale = defaultLocale;

export function getLocale(): Locale {
  return locale;
}

export function setLocale(newLocale: Locale) {
  locale = newLocale;
}
