import { initializeApp, getApps, getApp, FirebaseOptions, FirebaseApp } from "firebase/app";

const firebaseConfig: FirebaseOptions = {
  apiKey: `${process.env.GATSBY_FIREBASE_API_KEY}`,
  authDomain: `${process.env.GATSBY_FIREBASE_AUTH_DOMAIN}`,
  databaseURL: `${process.env.GATSBY_FIREBASE_DATABASE_URL}`,
  projectId: `${process.env.GATSBY_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.GATSBY_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.GATSBY_FIREBASE_APP_ID}`,
  measurementId: `${process.env.GATSBY_FIREBASE_MEASUREMENT_ID}`,
};

const initializeFirebaseApp = (firebaseConfig: FirebaseOptions) => {
  if (!getApps().length) {
    return initializeApp(firebaseConfig);
  } else {
    return getApp();
  }
};

const firebaseApp: FirebaseApp = initializeFirebaseApp(firebaseConfig);

export default firebaseApp;
