import { isBrowser } from "./ssr";

interface userData {
  userId: string;
  authToken: string;
}

export const setExternalId = ({ userId, authToken }: userData) => {
  if (isBrowser()) {
    window?.appboy?.changeUser(userId, authToken);
  }
};
