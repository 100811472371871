import React, { Component, ErrorInfo, useContext } from "react";
import { isTokenExpiredError } from "../../utils/error";
import { logError } from "../../utils/logging";
import ErrorPage, { BaseErrorPage } from "../ErrorPage/ErrorPage";
import { LoginContext } from "../LoginProvider/LoginProvider";
import { isApolloError } from "@apollo/client";
import { Link } from "../Link/Link";
import { LoginPage } from "../../pages";

interface ErrorBoundaryState {
  hasError: boolean;
  isTokenExpired: boolean;
}

/**
 * Decide if an error should be logged or not on the error boundary.
 * 1. Apollo errors are logged with apollo-link-error (src/utils/apollo-client.ts) and shouldn't be logged again.
 * 2. Token expired is not really an error.
 */
const shouldLogErrorOnBoundary = (error: Error) => {
  return !isApolloError(error) && !isTokenExpiredError(error);
};

/**
 * This error boundary is used both for capturing generic errors and expired JWT token.
 */
export class ErrorBoundary extends Component<unknown, ErrorBoundaryState> {
  public static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      isTokenExpired: isTokenExpiredError(error),
    };
  }

  constructor(props: any) {
    super(props);
    this.state = {
      hasError: false,
      isTokenExpired: false,
    };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    if (shouldLogErrorOnBoundary(error)) {
      logError("Error caught on boundary", error, {
        extras: { errorInfo },
        tags: { location: "ErrorBoundary" },
      });
    }
  }

  public render() {
    if (this.state.isTokenExpired) {
      return <ExpiredLoginPage />;
    }
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    // @ts-ignore
    return this.props.children;
  }
}

const ExpiredLoginPage: React.FunctionComponent = () => {
  const { logout } = useContext(LoginContext);
  logout();
  // Local dev: we don't have SSO, so we need to login with username and password
  // Prod: redirect to WP login (URL handled by web server proxy)
  const loginLink = <Link to={LoginPage}>click here</Link>;

  return (
    <BaseErrorPage title="Expired login">
      <p>Oops, apparently your login has expired. Please {loginLink} to login again.</p>
    </BaseErrorPage>
  );
};
