import { Script } from "gatsby";
import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { Locale } from "../../types/Locale";
import { pageContext } from "../PageProvider/PageProvider";

export function CookieScript() {
  const { locale } = useContext(pageContext);
  const isProduction = (process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV) === "production";

  if (locale !== Locale.SV) {
    return null;
  }

  return (
    <Helmet
      htmlAttributes={{
        lang: locale || "en",
      }}
    >
      <link rel="dns-prefetch" href="https://cdn.cookie-script.com" />
      {isProduction && locale === Locale.SV && (
        <Script
          type="text/javascript"
          charSet="UTF-8"
          data-cs-lang={locale}
          src="https://cdn.cookie-script.com/s/b61a9316295a3a092c7d869413779980.js"
        />
      )}
      {!isProduction && locale === Locale.SV && (
        <Script
          type="text/javascript"
          charSet="UTF-8"
          data-cs-lang={locale}
          src="https://cdn.cookie-script.com/s/ea099aaacf3a3f5fec0a79635091f285.js"
        />
      )}
    </Helmet>
  );
}
