import classnames from "classnames";
import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { LAPTOP, MOBILE_LG } from "../../utils/breakpoints";
import { default as colors } from "../../utils/colors.json";
import ResponsiveImage from "../Image/ResponsiveImage";

const BaseLayoutWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  .pagesection__content {
    width: 100%;
    max-width: 1200px;

    padding: 30px 20px 20px;
    @media (min-width: ${MOBILE_LG}) {
      padding: 70px 50px 50px;
    }
    @media (min-width: ${LAPTOP}) {
      padding: 70px 0 50px;
    }

    @media print {
      padding: 0;
    }
  }
`;

export const BaseLayout = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <BaseLayoutWrapper className={"pagesection " + className}>
      <div className="pagesection__content">{children}</div>
    </BaseLayoutWrapper>
  );
};

const DefaultVariantWrapper = styled(BaseLayoutWrapper)<HasBackground>`
  background-color: ${colors.WHITE};
  color: ${colors.GREY_DARK};

  .pagesection__title {
    font-weight: 700;
    font-size: 38px;
    line-height: 45px;

    &.pagesection__title--small {
      margin: 0px 0 14px;
      font-size: 28px;
      line-height: 32px;
      padding-top: 50px;
    }

    @media (min-width: ${MOBILE_LG}) {
      font-size: 62px;
      line-height: 65px;

      &.pagesection__title--small {
        font-size: 48px;
        line-height: 52px;
      }
    }
  }
  .pagesection__subtitle {
    &.pagesection__subtitle--h3 {
      font-size: 26px;
      line-height: 30px;
      letter-spacing: -0.2px;
      font-weight: 600;
      margin: 36px 0 10px;

      @media (min-width: ${MOBILE_LG}) {
        font-size: 40px;
        line-height: 45px;
        letter-spacing: -0.7px;
        margin: 54px 0 12px;
      }
    }
  }

  .pagesection__body {
    margin-bottom: 36px;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: normal;
    font-weight: normal;

    &.pagesection__body--preamble {
      font-size: 22px;
      line-height: 30px;
      letter-spacing: -0.15px;
      font-weight: 600;
    }

    @media (min-width: ${MOBILE_LG}) {
      max-width: 66%;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: normal;

      &.pagesection__body--preamble {
        font-size: 24px;
        line-height: 34px;
        letter-spacing: -0.25px;
      }
    }
  }
`;
interface HasBackground {
  hasBackgrounImage: boolean;
}

const InvertedVariantWrapper = styled(DefaultVariantWrapper)<HasBackground>`
  background-color: ${colors.GREY_DARK};
  color: ${colors.WHITE};
`;

const GreenVariantWrapper = styled(DefaultVariantWrapper)<HasBackground>`
  background-color: ${colors.BEIGE};
  color: ${colors.GREY_DARK};
`;

const BlueVariantWrapper = styled(DefaultVariantWrapper)<HasBackground>`
  background-color: ${colors.BLUE};
  color: ${colors.WHITE};
`;

const LightBlueVariantWrapper = styled(DefaultVariantWrapper)<HasBackground>`
  background-color: ${colors.BLUE_LIGHTER};
  color: ${colors.GREY_DARK};
`;

const GreenHeroVariantWrapper = styled(DefaultVariantWrapper)<HasBackground>`
  background-color: ${({ hasBackgrounImage }: any) => (hasBackgrounImage ? "transparent" : colors.GREEN_DARK)};
  color: ${colors.WHITE};
  padding: 30px 0;

  .pagesection__content {
    padding: 30px 20px 0;

    @media (min-width: ${MOBILE_LG}) {
      padding: 70px 50px 50px;
    }
  }

  .pagesection__title {
    margin: 0;
    font-size: 38px;
    line-height: 45px;
    letter-spacing: -0.15px;

    @media (min-width: ${MOBILE_LG}) {
      font-size: 70px;
      line-height: 73px;
      letter-spacing: -0.34px;
    }
  }

  .pagesection__body {
    margin: 0 0 30px;
  }
`;

const BackgroundImageContainer = styled.div`
  position: absolute;
  top: 0;
  line-height: 0;
  z-index: -2;
  height: 100%;
  overflow: hidden;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  min-width: 100%;
`;

const BackgroundImage = styled(ResponsiveImage)`
  position: absolute;
  max-width: none;

  top: auto;
  left: 50%;
  transform: translateX(-50%);
  width: auto;
  height: 100%;

  @media (min-width: 518px) {
    top: 50%;
    left: auto;
    transform: translateY(-50%);
    width: 100%;
    height: auto;
  }
`;

const getWrapperForVariant = (variant: WrapperVariant) => {
  switch (variant) {
    case "inverted":
      return InvertedVariantWrapper;
    case "green":
      return GreenVariantWrapper;
    case "blue":
      return BlueVariantWrapper;
    case "light-blue":
      return LightBlueVariantWrapper;
    case "green-hero":
      return GreenHeroVariantWrapper;
    default:
      return DefaultVariantWrapper;
  }
};

type WrapperVariant = "default" | "inverted" | "green" | "blue" | "light-blue" | "green-hero";
const PageSectionWithVariants = ({
  variant = "default",
  children,
  backgroundImage,
}: {
  variant?: WrapperVariant;
  children: ReactNode;
  backgroundImage?: string;
}) => {
  const VariantWrapper = getWrapperForVariant(variant);
  return (
    <VariantWrapper className="pagesection" hasBackgrounImage={!!backgroundImage}>
      <div className="pagesection__content">{children}</div>
      {backgroundImage && (
        <BackgroundImageContainer>
          <BackgroundImage
            path={backgroundImage}
            alt=""
            width={2400}
            height={1600}
            sizes="100vw"
            dimensions={[
              { width: 150, height: 100 },
              { width: 200, height: 133 },
              { width: 267, height: 178 },
              { width: 400, height: 267 },
              { width: 600, height: 400 },
              { width: 800, height: 533 },
              { width: 1000, height: 667 },
              { width: 1200, height: 800 },
              { width: 1600, height: 1067 },
              { width: 2000, height: 1333 },
              { width: 2400, height: 1600 },
              { width: 4800, height: 3200 },
            ]}
            defaultDimension={{ width: 1200, height: 800 }}
          />
        </BackgroundImageContainer>
      )}
    </VariantWrapper>
  );
};

type HeadingProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement>;
type ParagraphProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLParagraphElement>, HTMLParagraphElement>;

type TitleVariant = "small";
export const PageSectionTitle = ({ variant, children, ...rest }: { variant?: TitleVariant } & HeadingProps) => {
  return (
    <h1
      className={classnames("pagesection__title", {
        "pagesection__title--small": variant === "small",
      })}
      {...rest}
    >
      {children}
    </h1>
  );
};

type BodyVariant = "preamble";
export const PageSectionBody = ({ variant, children }: { variant?: BodyVariant } & ParagraphProps) => {
  return (
    <div
      className={classnames("pagesection__body", {
        "pagesection__body--preamble": variant === "preamble",
      })}
    >
      {children}
    </div>
  );
};

type SubtitleVariant = "h2" | "h3";
export const PageSectionSubtitle = ({ variant, children }: { variant?: SubtitleVariant } & HeadingProps) => {
  const Element = variant || "h2";
  return (
    <Element
      className={classnames("pagesection__subtitle", {
        "pagesection__subtitle--h3": variant === "h3",
      })}
    >
      {children}
    </Element>
  );
};

export default PageSectionWithVariants;
