import React from "react";
import styled from "@emotion/styled";
import { MOBILE_MD } from "../../utils/breakpoints";
import { Dimension, getSrc, getSrcSet } from "../../utils/image";

const StyledImage = styled.img<{ rounded: boolean }>`
  width: 100%;
  height: auto;
  max-width: 100%;
  display: block;

  @media print {
    @-moz-document url-prefix() {
      max-width: 224px;
    }
  }

  @media (max-width: ${MOBILE_MD}) {
    border-radius: 8px;
  }

  ${({ rounded }) => rounded && "border-radius: 15px;"}
`;

const ResponsiveImage = ({
  path,
  alt,
  width,
  height,
  sizes,
  dimensions,
  defaultDimension,
  rounded = false,
  className,
}: {
  path: string;
  alt: string;
  width: number;
  height: number;
  sizes?: string;
  dimensions: Dimension[];
  defaultDimension: Dimension;
  rounded?: boolean;
  className?: string;
}) => {
  return (
    <StyledImage
      className={className}
      alt={alt}
      width={width}
      height={height}
      sizes={sizes}
      srcSet={getSrcSet(path, dimensions)}
      src={getSrc(path, defaultDimension)}
      rounded={rounded}
    />
  );
};

export default ResponsiveImage;
