import { GetEssentialUserInfo_me } from "../types/GetEssentialUserInfo";
import { GetUsers_getDDProMembers } from "../types/GetUsers";

export const upperFirst = (str: string) => {
  return str[0].toUpperCase() + str.substring(1).toLowerCase();
};

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getUserInitials = ({
  firstName,
  lastName,
}: GetEssentialUserInfo_me | GetUsers_getDDProMembers) => {
  const firstNameInitial = firstName?.[0]?.toUpperCase() ?? "";
  const lastNameInitial = lastName?.[0]?.toUpperCase() ?? "";

  return `${firstNameInitial}${lastNameInitial}`;
};

export const getShortenString = (text: string, numberOfChar: number) => {
  return `${text.substring(0, numberOfChar)}${text.length > numberOfChar ? "..." : ""}`;
};

export const getNameInitial = (name: string) => name.charAt(0).toUpperCase();
