import React, { createContext, PropsWithChildren, useMemo } from "react";
import queryString from "query-string";
import { InternalPage, isClientSidePage, Page, PageContext, pages } from "../../pages";
import { defaultLocale, Locale } from "../../types/Locale";
import { setLocale } from "../../utils/locale";
import { useLocation } from "@reach/router";
// @ts-ignore
import { pick } from "@reach/router/lib/utils";

export const pageContext = createContext<PageContext<any, any, any>>({
  name: "",
  locale: defaultLocale,
  params: undefined,
  query: undefined,
  extraContext: undefined,
});

export function PageProvider<Context, Params, Query>({
  pageContext: pageContextData,
  children,
}: PageProviderProps<Context, Params, Query>) {
  const context = usePageContext(pageContextData);
  setLocale(context.locale);
  return <pageContext.Provider value={context}>{children}</pageContext.Provider>;
}

type PageProviderProps<Context, Params, Query> = PropsWithChildren<{
  pageContext: PageContext<Context, Params, Query>;
}>;

function usePageContext<Context, Params, Query>(
  pageContext: PageContext<Context, Params, Query>
): PageContext<Context, Params, Query> {
  const { name: pageName, locale, params: staticParams } = pageContext;
  // XXX: `page` could be undefined until we migrate all pages to the new `createPage` function
  const page = pages[pageName] as InternalPage<Context, Params, Query> | undefined;

  const location = useLocation();
  const dynamicParams = useDynamicParams(page, locale, location.pathname);
  const rawQuery = useQuery(location.search);

  const [params, query] = useMemo<[Params, Query]>(() => {
    const process = page?.processParamsAndQuery || defaultProcessParamsAndQuery;
    return process(locale, staticParams, dynamicParams, rawQuery);
  }, [page?.processParamsAndQuery, locale, staticParams, dynamicParams, rawQuery]);

  return {
    ...pageContext,
    params,
    query,
  };
}

function useDynamicParams<Context, Params, Query>(
  page: Page<Context, Params, Query> | undefined,
  locale: Locale,
  path: string
): Partial<Record<string, string>> {
  const routerPaths = page && isClientSidePage(page) ? page.routerPaths(locale) : [];

  const match = pick(
    routerPaths.map((routerPath) => ({
      path: routerPath,
    })),
    path
  );

  if (match) {
    return match.params;
  }

  return {};
}

function useQuery(search: string): Partial<Record<string, string>> {
  return useMemo(() => queryString.parse(search), [search]) as any;
}

function defaultProcessParamsAndQuery<Params, Query>(
  locale: Locale,
  staticParams: Params,
  dynamicParams: Partial<Record<string, string>>,
  rawQuery: Partial<Record<string, string>>
): [Params, Query] {
  const params: Params = {
    ...staticParams,
    ...dynamicParams,
  };
  const query: Query = rawQuery as any;

  return [params, query];
}
