import { FaSpinner } from "@react-icons/all-files/fa/FaSpinner";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

export const LoadingIcon = styled(FaSpinner)`
  animation: ${spin} 1s linear infinite;
`;
