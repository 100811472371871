import { t } from "@lingui/macro";
import { useMemo } from "react";
import { Locale } from "../../types/Locale";
import { dateLong, mealLabel, weekdayLong, weekdayShort, mealDifficulty } from "../../utils/translations";

export type Translations = ReturnType<typeof useTranslations>;

export const defaultTranslations: Translations = {
  buttonLogIn: "",
  buttonLogOut: "",
  buttonFreeTrial: "",

  linkLowCarb: "",
  linkAllMealPlans: "",
  linkAllRecipes: "",
  linkHealth: "",
  linkVideo: "",
  linkNews: "",
  linkExploreDDPlus: "",
  linkAboutMembership: "",

  linkMyAccount: "",
  linkMyPage: "",
  linkMyFavorites: "",
  linkMyMealPlanner: "",

  errors: {
    default: {
      title: "",
      message: "",
    },
  },

  meals: {
    mealLabel: () => "",
  },

  mealDifficulty: {
    mealDifficulty: () => "",
  },

  mealPlans: {
    defaultTitle: "",
  },

  dateLong: () => "",
  weekdayShort: () => "",
  weekdayLong: () => "",
};

export function useTranslations(locale: Locale) {
  const translations = useMemo(() => getTranslations(locale), [locale]);

  return translations;
}

function getTranslations(locale: Locale) {
  return {
    buttonLogIn: t({
      id: "common.button.log-in",
      message: "Log in",
      comment: 'Label found on "log in" buttons, links and menu entries',
    }),
    buttonLogOut: t({
      id: "common.button.log-out",
      message: "Log out",
      comment: 'Label found on "log out" buttons, links and menu entries',
    }),
    buttonFreeTrial: t({
      id: "common.button.free-trial",
      message: "Free trial",
      comment: 'Label found on "free trial" buttons, links and menu entries',
    }),

    linkLowCarb: t({
      id: "common.link.low-carb",
      message: "Low carb & keto",
      comment: 'Label for link to "low carb" main page',
    }),
    linkAllMealPlans: t({
      id: "common.link.all-meal-plans",
      message: "Meal plans",
      comment: 'Label for link to "all meal plans" page',
    }),
    linkAllRecipes: t({
      id: "common.link.all-recipes",
      message: "Recipes",
      comment: 'Label for link to "all recipes" page',
    }),
    linkHealth: t({
      id: "common.link.health",
      message: "Health",
      comment: 'Label for link to "health" page',
    }),
    linkVideo: t({
      id: "common.link.video",
      message: "Video",
      comment: 'Label for link to "video" page',
    }),
    linkNews: t({
      id: "common.link.news",
      message: "News",
      comment: 'Label for link to "news" page',
    }),
    linkExploreDDPlus: t({
      id: "common.link.explore-dd-plus",
      message: "Explore",
      comment: 'Label for link to "explore DD plus" page',
    }),
    linkAboutMembership: t({
      id: "common.link.about-membership",
      message: "About",
      comment: 'Label for link to "about membership" page',
    }),

    linkMyAccount: t({
      id: "common.link.my-account",
      message: "My account",
      comment: 'Label for link to "my account" page',
    }),
    linkMyPage: t({
      id: "common.link.my-page",
      message: "My page",
      comment: 'Label for link to "my page" page',
    }),
    linkMyFavorites: t({
      id: "common.link.my-favorites",
      message: "My favorites",
      comment: 'Label for link to "my favorites" page',
    }),
    linkMyMealPlanner: t({
      id: "common.link.my-meal-planner",
      message: "My meal plans",
      comment: 'Label for link to "my meal planner" page',
    }),

    errors: {
      default: {
        title: t({
          id: "common.errors.default.title",
          message: "Oops, something went wrong.",
        }),
        message: t({
          id: "common.errors.default.message",
          message:
            "Please try again shortly. If the issue persists, please reach out to us at support@dietdoctor.com",
        }),
      },
    },

    meals: {
      mealLabel,
    },
    mealDifficulty: {
      mealDifficulty,
    },

    mealPlans: {
      defaultTitle: t({
        id: "common.mealPlans.defaultTitle",
        message: "Unnamed meal plan",
        comment: "Default meal plan title which is used when the meal plan does not have a title",
      }),
    },

    dateLong: dateLong(locale),
    weekdayShort: weekdayShort(locale),
    weekdayLong: weekdayLong(locale),
  };
}
