import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable,
  Operation,
  RequestHandler,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import ApolloLinkTimeout from "apollo-link-timeout";
import fetch from "cross-fetch";
import { Locale } from "../types/Locale";
import { isBrowser } from "../utils/ssr";
import { fetchAndCacheTokenUsingFirebase, getLocalTokenOrFetchNewOne } from "./auth";
import { isLocalDevelopment } from "./environment";
import { isTokenExpiredError } from "./error";
import { getLocale } from "./locale";
import { logError } from "./logging";

const SENTRY_GRAPHQL_ERROR_FINGERPRINT = "graphql-error";

/**
 * Each locale has its own GraphQL API endpoint.
 */
const localizedHttpLinks: Record<Locale, HttpLink> = {
  [Locale.EN]: new HttpLink({
    fetch,
    uri: `${process.env.GATSBY_GRAPHQL_API_URL}`,
  }),
  [Locale.ES]: new HttpLink({
    fetch,
    uri: `${process.env.GATSBY_ES_GRAPHQL_API_URL}`,
  }),
  [Locale.SV]: new HttpLink({
    fetch,
    uri: `${process.env.GATSBY_SE_GRAPHQL_API_URL}`,
  }),
  [Locale.ZU]: new HttpLink({
    fetch,
    uri: `${process.env.GATSBY_GRAPHQL_API_URL}`,
  }),
};

/**
 * The HTTP link selects the correct GraphQL API endpoint based on the current locale.
 */
const httpLink = new ApolloLink((operation) => {
  const locale = getLocale();
  const link = localizedHttpLinks[locale];
  return link.request(operation);
});

const updateOperationAuthHeader = (operation: Operation, token: string | null) => {
  const requestHeaders = { ...operation.getContext().headers };
  if (!token) {
    delete requestHeaders.authorization;
  } else {
    requestHeaders.authorization = `Bearer ${token}` || "";
  }

  // add the authorization to the headers
  operation.setContext({ headers: requestHeaders });
};

const authMiddlewareRequestHandler: RequestHandler = (operation, forward) => {
  if (!isBrowser()) {
    return null;
  }
  // "Pattern" used to convert a promise into an observable
  return new Observable((observer) => {
    getLocalTokenOrFetchNewOne()
      .then((token) => {
        if (token) {
          updateOperationAuthHeader(operation, token);
        }
        const subscriber = {
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        };

        // Retry last failed request
        return forward(operation).subscribe(subscriber);
      })
      .catch((error) => {
        // Error fetching token
        const { requestId } = operation.getContext();
        logError(`Error fetching token`, error, {
          tags: {
            location: "authMiddleware",
            requestId: requestId || undefined,
          },
          extras: {
            operation: operation.operationName,
            variables: operation.variables,
          },
        });
        // TODO: should throw? what about anon requests?
        observer.error(error);
      });
  });
};

const authMiddlewareFirebase = new ApolloLink(authMiddlewareRequestHandler);

const ignoredOperations = ["GetMealPlanSettings", "generateMealplan"];
const ignoredMessages = [
  "failed to fetch user state on time",
  "firebase token fetch failed",
  "email already registered",
  "401 unauthorized",
  "403 forbidden",
  "graphql: user already exists",
  "graphql: unauthenticated",
  "graphql error: 401 unauthorized",
  "graphql error: 403 forbidden",
  "could not find a melaplan with the provided slug",
  "user does not have permission to see this mealplan",
  "must have an active membership to perform this action",
  "request must be authenticated to perform this action",
];

/**
 * Log errors with additional information to help debugging.
 */
const errorMiddleware = onError(({ graphQLErrors, networkError, operation }) => {
  const { isTokenRetryRequest, requestId } = operation.getContext();

  // We don't need to log an error to sentry when these operations fail
  if (ignoredOperations.includes(operation.operationName)) {
    graphQLErrors = undefined;
  }

  if (graphQLErrors) {
    graphQLErrors.forEach((graphQLError) => {
      // We don't need to log an error to sentry when it's these error messages
      if (ignoredMessages.includes(graphQLError.message.toLocaleLowerCase())) {
        return;
      }
      logError(`GraphQL error: ${graphQLError.message}`, graphQLError, {
        tags: {
          location: "errorMiddleware",
          type: "GraphQLError",
          requestId: requestId || undefined,
        },
        extras: {
          path: graphQLError.path,
          originalError: graphQLError.originalError,
          operation: operation.operationName,
          variables: operation.variables,
          isTokenRetryRequest: !!isTokenRetryRequest,
        },
        // Group GraphQL errors into issues exclusively by their message
        fingerprint: [SENTRY_GRAPHQL_ERROR_FINGERPRINT, graphQLError.message],
      });
    });
  }

  // Do not log token expired errors, except when they come from token refresh retry
  if (networkError && (!isTokenExpiredError(networkError) || isTokenRetryRequest)) {
    logError(`Network error: ${networkError.message}`, networkError, {
      tags: {
        location: "errorMiddleware",
        type: "NetworkError",
        requestId: requestId || undefined,
      },
      extras: {
        operation: operation.operationName,
        variables: operation.variables,
        isTokenRetryRequest: !!isTokenRetryRequest,
      },
    });
  }
});

/**
 * Add information from response headers to the Apollo operation context to improve logging.
 */
const responseHeadersLoggingMiddleware = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      // Request ID generated by backend services (used on Honeycomb tracing)
      const requestId = headers.get("request-id");
      operation.setContext((prevContext: Record<string, any>) => ({
        ...prevContext,
        requestId,
      }));
    }

    return response;
  });
});

// TODO: Still needed? Since fetchAndCacheTokenUsingFirebase always returns a valid token.
/**
 * Refresh token on token expired errors:
 * 1) Catches expired token errors
 * 2) Try to refresh the token with cookies (prod only)
 * 3) (If successful) Save token locally, update operation header and retry request
 * @see https://github.com/apollographql/apollo-link/issues/646
 * @see https://stackoverflow.com/a/51321068
 */
const refreshTokenMiddleware = onError(({ networkError, operation, forward }) => {
  if (isTokenExpiredError(networkError) && !isLocalDevelopment()) {
    return new Observable((observer) => {
      fetchAndCacheTokenUsingFirebase()
        .then((token) => {
          // User is not logged in
          if (!token) {
            throw new Error("Non-logged user accessing protected resource");
          }
          updateOperationAuthHeader(operation, token);
          operation.setContext((prevContext: Record<string, any>) => ({
            ...prevContext,
            isTokenRetryRequest: true,
          }));
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          // No refresh or client token available
          const { requestId } = operation.getContext();
          logError(`Error refreshing token`, error, {
            tags: {
              location: "refreshTokenMiddleware",
              requestId: requestId || undefined,
            },
            extras: {
              operation: operation.operationName,
              variables: operation.variables,
              originalError: networkError,
            },
          });
          observer.error(error);
        });
    });
  }
});

const timeoutLink = new ApolloLinkTimeout(72000); // 72 second timeout

const link = ApolloLink.from([
  errorMiddleware,
  refreshTokenMiddleware,
  authMiddlewareFirebase,
  responseHeadersLoggingMiddleware,
  timeoutLink,
  httpLink, // Make sure httpLink is always last in this array
]);
const cache = new InMemoryCache({
  typePolicies: {
    RecipeIngredient: {
      keyFields: false,
    },
    MembershipSubscription: {
      merge: false,
    },
  },
});
export const client = new ApolloClient({ link, cache });
