import { State } from "xstate";
import { GetUsers_getDDProMembers } from "../../../types/GetUsers";
import { useNavigate } from "../../../components/Link/Link";
import { useDDProTranslations } from "../useDDProTranslations";

export type ProMachineState = State<
  ProMachineContext,
  ProMachineEvents,
  any,
  {
    value: any;
    context: ProMachineContext;
  }
>;

export interface ProMachineContext {
  users: Array<GetUsers_getDDProMembers>;
  currentUser: AddUserPayload | GetUsers_getDDProMembers | undefined;
  groupID: string;
  groupTitle: string;
  subscriptionPayer: SubscriptionPayer;
  filter: string;
  addingMemberNotification?: string;
  removingMemberNotification?: string;
}

export type ProMachineEvents =
  | SubmitEvent
  | CloseEvent
  | GoToInfoEvent
  | ViewMemberDetailsEvent
  | AddMemberEvent
  | CancelEvent
  | GoToListEvent
  | GoToQREvent
  | AddUserEvent
  | RemoveMemberEvent
  | FilterChangeEvent;

type GoToInfoEvent = {
  type: "GO_TO_INFO";
};

type AddMemberEvent = {
  type: "ADD_MEMBER";
};

export type ViewMemberDetailsEvent = {
  type: "VIEW_MEMBER_DETAILS";
  member: GetUsers_getDDProMembers;
};

export type SubmitEvent = {
  type: "SUBMIT";
  member: Partial<AddUserPayload>;
};

type CancelEvent = {
  type: "CANCEL";
};

type CloseEvent = {
  type: "CLOSE";
};

type GoToQREvent = {
  type: "GO_TO_QR";
};

type GoToListEvent = {
  type: "GO_TO_LIST";
};

type AddUserEvent = { type: "ADD_MEMBER"; member: AddUserPayload };
type RemoveMemberEvent = { type: "REMOVE_MEMBER"; member: GetUsers_getDDProMembers };

export type FilterChangeEvent = { type: "FILTER_CHANGE"; input: string };

export type MachineOptions = {
  navigate: ReturnType<typeof useNavigate>;
  firstState?: string;
  tt: ReturnType<typeof useDDProTranslations>;
};

export type GroupIdPayload = {
  getDDProGroups: Array<{ id: string; title: string; subscriptionPayer: SubscriptionPayer }>;
};

export type AddUserPayload = {
  groupId: string;
  firstName: string;
  lastName: string;
  email: string;
  subscriptionTerm: string;
};

export enum SubscriptionPayer {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
  UNKNOWN_PAYER = "UNKNOWN_PAYER",
}

export enum IDDProLanguage {
  LANGUAGE_UNSPECIFIED = 0,
  EN = 1,
  SE = 2,
  ES = 3,
}
