import React, { PropsWithChildren, ReactNode } from "react";
import { PageContext } from "../../pages";
import { PageProvider } from "../PageProvider/PageProvider";
import { I18nProvider } from "../I18nProvider/I18nProvider";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import "./global.css";
import { Layout } from "../../utils/layout";
import { NoLayout } from "../NoLayout/NoLayout";
import { NoFooter } from "../NoFooter/NoFooter";
import { DefaultLayout } from "../DefaultLayout/DefaultLayout";
import { CookieScript } from "../CookieScript/CookieScript";
import { ErrorBoundary } from "../ErrorBoundary/ErrorBoundary";

function getLayout(layout?: Layout) {
  switch (layout) {
    case Layout.NO_LAYOUT:
      return NoLayout;
    case Layout.NO_FOOTER:
      return NoFooter;
    default:
      return DefaultLayout;
  }
}

type PageWrapperProps<Props, Params, Query> = PropsWithChildren<{
  pageContext: PageContext<Props, Params, Query>;
}>;

function PageWrapper<Props, Params, Query>({ children, pageContext }: PageWrapperProps<Props, Params, Query>) {
  const LayoutComponent = getLayout(pageContext.layout);

  return (
    <PageProvider pageContext={pageContext}>
      <I18nProvider>
        <ThemeProvider theme={theme}>
          <LayoutComponent>
            <CookieScript />
            <ErrorBoundary>{children}</ErrorBoundary>
          </LayoutComponent>
        </ThemeProvider>
      </I18nProvider>
    </PageProvider>
  );
}

interface WrapPageElementParams {
  element: ReactNode;
  props: { pageContext: PageContext<any, any, any> };
}

// Do not remove!, used by Gatsby
// ts-prune-ignore-next
export function wrapPageElement({ element, props: { pageContext } }: WrapPageElementParams) {
  return <PageWrapper pageContext={pageContext}>{element}</PageWrapper>;
}
