/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum Brightness {
  DARK = "DARK",
  DARKER = "DARKER",
  LIGHT = "LIGHT",
  LIGHTER = "LIGHTER",
  UNKNOWN = "UNKNOWN",
}

export enum CarbsStrictness {
  LIBERAL = "LIBERAL",
  MODERATE = "MODERATE",
  STRICT = "STRICT",
  UNKNOWN = "UNKNOWN",
}

export enum Cuisine {
  ASIAN = "ASIAN",
  CUISINE_UNSPECIFIED = "CUISINE_UNSPECIFIED",
  INDIAN = "INDIAN",
  ITALIAN = "ITALIAN",
  MEDITERRANEAN = "MEDITERRANEAN",
  MEXICAN = "MEXICAN",
  MIDDLE_EASTERN = "MIDDLE_EASTERN",
}

export enum DDProGroupSubscriptionPayer {
  ADMIN = "ADMIN",
  MEMBER = "MEMBER",
  UNKNOWN_PAYER = "UNKNOWN_PAYER",
}

export enum DDProSubscriptionTerm {
  HALF_YEAR_FIXED = "HALF_YEAR_FIXED",
  NINE_MONTHS_FIXED = "NINE_MONTHS_FIXED",
  RECURRING = "RECURRING",
  THREE_MONTHS_FIXED = "THREE_MONTHS_FIXED",
  UNKNOWN_TERM = "UNKNOWN_TERM",
  YEAR_FIXED = "YEAR_FIXED",
}

export enum Diet {
  DIET_UNSPECIFIED = "DIET_UNSPECIFIED",
  VEGAN = "VEGAN",
  VEGETARIAN = "VEGETARIAN",
}

export enum DietChallenge {
  BAD_FOOD = "BAD_FOOD",
  CRAVINGS = "CRAVINGS",
  DIET_CHALLENGE_UNSPECIFIED = "DIET_CHALLENGE_UNSPECIFIED",
  HUNGER = "HUNGER",
  LIMITED_OPTIONS = "LIMITED_OPTIONS",
  NO_INTEREST_IN_DIET = "NO_INTEREST_IN_DIET",
}

export enum Direction {
  ASC = "ASC",
  DESC = "DESC",
}

export enum Dish {
  CASSEROLE = "CASSEROLE",
  DISH_UNSPECIFIED = "DISH_UNSPECIFIED",
  GRILL = "GRILL",
  OMELET = "OMELET",
  PIZZA = "PIZZA",
  SALAD = "SALAD",
  SOUP = "SOUP",
  STEW = "STEW",
  STIR_FRY = "STIR_FRY",
}

/**
 * The kinds/types of top-level domain objects we can query on
 */
export enum DomainKind {
  MEALPLAN = "MEALPLAN",
  RECIPE = "RECIPE",
}

export enum FoodType {
  BEEF = "BEEF",
  DAIRY = "DAIRY",
  EGGS = "EGGS",
  FISH = "FISH",
  FOOD_TYPE_UNSPECIFIED = "FOOD_TYPE_UNSPECIFIED",
  LAMB = "LAMB",
  NUTS = "NUTS",
  PORK = "PORK",
  POULTRY = "POULTRY",
  SHELLFISH = "SHELLFISH",
}

export enum Gender {
  FEMALE = "FEMALE",
  MALE = "MALE",
  OTHER = "OTHER",
}

export enum HealthCondition {
  HEALTH_CONDITION_UNSPECIFIED = "HEALTH_CONDITION_UNSPECIFIED",
  HIGH_BLOOD_PRESSURE = "HIGH_BLOOD_PRESSURE",
  TYPE1_DIABETES = "TYPE1_DIABETES",
  TYPE2_DIABETES = "TYPE2_DIABETES",
}

/**
 * Represents the origin of a mealplan,
 * meaning how it appeared in this world
 * (was it created by the user via the UI,
 * generated via personalization or copied)
 */
export enum MealPlanOrigin {
  COPIED = "COPIED",
  CREATED = "CREATED",
  GENERATED = "GENERATED",
}

export enum MealType {
  BREAKFAST = "BREAKFAST",
  DINNER = "DINNER",
  LUNCH = "LUNCH",
}

export enum MeasurementSystem {
  IMPERIAL = "IMPERIAL",
  MEASUREMENT_SYSTEM_UNSPECIFIED = "MEASUREMENT_SYSTEM_UNSPECIFIED",
  METRIC = "METRIC",
}

export enum MembershipSubscriptionPlan {
  LIFETIME = "LIFETIME",
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export enum MembershipSubscriptionProvider {
  APPSTORE = "APPSTORE",
  BRAINTREE = "BRAINTREE",
  GENERIC = "GENERIC",
  GOOGLE_PLAY = "GOOGLE_PLAY",
  PAYPAL = "PAYPAL",
  STRIPE = "STRIPE",
  UNKNOWN = "UNKNOWN",
}

export enum MembershipSubscriptionStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
  INVALID = "INVALID",
  NEW = "NEW",
  UNKNOWN = "UNKNOWN",
}

export enum ProteinLevel {
  ADEQUATE_PROTEIN = "ADEQUATE_PROTEIN",
  HIGH_PROTEIN = "HIGH_PROTEIN",
  UNKNOWN_PROTEIN = "UNKNOWN_PROTEIN",
}

export enum Ranking {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
}

export enum StripeCurrency {
  SEK = "SEK",
  USD = "USD",
  USD_ES = "USD_ES",
}

export enum StripeSubscriptionPlan {
  MONTHLY = "MONTHLY",
  YEARLY = "YEARLY",
}

export enum VideoType {
  VIMEO = "VIMEO",
  YOUTUBE = "YOUTUBE",
}

export enum WeightLossGoal {
  HIGH = "HIGH",
  LOW = "LOW",
  MEDIUM = "MEDIUM",
  NONE = "NONE",
}

export interface CommunicationPrefsInput {
  subscribeMemberEmails: boolean;
  subscribeNewsletterEmails: boolean;
}

export interface CreateDDProMemberInput {
  groupId: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
  subscriptionTerm?: DDProSubscriptionTerm | null;
}

export interface CreateDDProMemberWithGroupIdInput {
  groupId: string;
  firstName?: string | null;
  lastName?: string | null;
  email: string;
}

export interface CreateStripeCheckoutSessionInput {
  priceId: string;
  cancelUrl: string;
  successUrl: string;
}

export interface DeleteDDProMemberInput {
  id: string;
}

export interface GetBrazeSDKAuthTokenInput {
  z?: boolean | null;
}

export interface GetDDProMembersInput {
  groupId: string;
}

export interface HealthConditionsInput {
  diabetesType1: boolean;
  diabetesType2: boolean;
  highBloodPressure: boolean;
}

export interface ItemWithServings {
  id: string;
  servings: number;
}

export interface JoinDDProGroupInput {
  signedURL: string;
  firstName: string;
  lastName: string;
  taxResidence?: string | null;
  password: string;
  language?: string | null;
}

export interface MealplanDayInput {
  name: string;
  breakfast?: MealplanMealInput | null;
  lunch?: MealplanMealInput | null;
  dinner?: MealplanMealInput | null;
  servings?: number | null;
}

/**
 * The input object for mutating mealplans
 */
export interface MealplanInput {
  title?: string | null;
  description?: string | null;
  schedule?: MealplanDayInput[] | null;
  shared?: boolean | null;
  origin?: MealPlanOrigin | null;
  startTime?: string | null;
  endTime?: string | null;
  servings?: number | null;
}

export interface MealplanMealInput {
  active: boolean;
  recipes: string[];
  mealplanRecipe?: MealplanRecipeInput[] | null;
}

export interface MealplanPreferencesInput {
  gender: Gender;
  height: Ranking;
  age: number;
  weightLossGoal: WeightLossGoal;
  activityLevel: Ranking;
  strictness: CarbsStrictness;
  frequency: string;
  budget: Ranking;
  cookingInterest: Ranking;
  leftover: boolean;
  exclusions?: string[] | null;
  healthConditions: HealthConditionsInput;
  proteinLevel?: ProteinLevel | null;
  raw?: string | null;
  avoidedFoods?: FoodType[] | null;
  diet?: Diet | null;
}

/**
 * The entity used to create a mealplan recipe with sevings for that particular recipe.
 */
export interface MealplanRecipeInput {
  recipe: string;
  servings: number;
}

export interface PayPalSubscriptionInput {
  subscriptionId: string;
}

export interface Range {
  min?: number | null;
  max?: number | null;
}

export interface StripeSubscriptionInput {
  paymentMethodId: string;
  plan: StripeSubscriptionPlan;
  currency: StripeCurrency;
  trialPeriodDays?: number | null;
  d?: number;
}

export interface UpdateStripePaymentMethodInput {
  paymentMethodId: string;
}

export interface UpdateUserInput {
  firstName?: string | null;
  lastName?: string | null;
  taxResidence?: string | null;
  language?: string | null;
  measurementSystem?: MeasurementSystem | null;
  gender?: Gender | null;
  birthYear?: number | null;
  heightCm?: number | null;
  physicalActivity?: Ranking | null;
  healthConditions?: HealthCondition[] | null;
  desiredCuisines?: Cuisine[] | null;
  desiredDishes?: Dish[] | null;
  avoidedFoods?: FoodType[] | null;
  foodBudget?: Ranking | null;
  cookingTimeExtent?: Ranking | null;
  cookingSkill?: Ranking | null;
  carbTolerance?: Ranking | null;
  proteinTolerance?: Ranking | null;
  dietEffectiveness?: Ranking | null;
  dailyMealsCount?: number | null;
  diet?: Diet | null;
  dietChallenge?: DietChallenge | null;
  leftovers?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
