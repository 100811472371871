import { ClientSidePage, ExternalPage, RegularPage } from "./pages";

export function createRegularPage<Context = undefined, Params = undefined, Query = undefined>(
  page: Omit<RegularPage<Context, Params, Query>, "type">
): RegularPage<Context, Params, Query> {
  return {
    type: "regular",
    ...page,
  };
}

export function createClientSidePage<Context = undefined, Params = undefined, Query = undefined>(
  page: Omit<ClientSidePage<Context, Params, Query>, "type">
): ClientSidePage<Context, Params, Query> {
  return {
    type: "clientside",
    ...page,
  };
}

export function createExternalPage<Params = undefined, Query = undefined>(
  page: Omit<ExternalPage<Params, Query>, "type">
): ExternalPage<Params, Query> {
  return {
    type: "external",
    ...page,
  };
}
