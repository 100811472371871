import React from "react";
import useAuth from "../../graphql/useAuth/useAuth";
import { Auth } from "../../types/Auth";

export const LoginProvider: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const auth = useAuth();

  return <LoginContext.Provider value={auth}>{children}</LoginContext.Provider>;
};

export const LoginContext = React.createContext<Auth>({
  loading: false,
  loggedIn: false,
  premium: false,
  subscription: undefined,
  user: undefined,
  firebaseUserId: undefined,
  error: undefined,
  loginWithPassword: () => Promise.resolve(),
  logout: async () => undefined,
});
