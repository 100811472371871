module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#F7F8FB","theme_color":"#F7F8FB","display":"minimal-ui","icon":"static/favicon.svg","icons":[{"src":"/dd-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"/dd-icon-512x512.png","sizes":"512x512","type":"image/png"}],"start_url":"/","lang":"en","name":"Diet Doctor","short_name":"Diet Doctor","description":"Making Low Carb and Keto Simple","localize":[{"start_url":"/se/","lang":"sv","name":"Diet Doctor","short_name":"Diet Doctor","description":"Making Low Carb and Keto Simple"},{"start_url":"/es/","lang":"es","name":"Diet Doctor","short_name":"Diet Doctor","description":"Making Low Carb and Keto Simple"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"aeaaa5d4baa7881ca8c16b0e33ce7c95"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WFRPMZS","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"function () {\n                    var _a, _b;\n                    const parseJwt = (token) => {\n                        const base64Url = token.split(\".\")[1];\n                        const base64 = base64Url.replace(/-/g, \"+\").replace(/_/g, \"/\");\n                        const jsonPayload = decodeURIComponent(atob(base64)\n                            .split(\"\")\n                            .map(function (c) {\n                            return \"%\" + (\"00\" + c.charCodeAt(0).toString(16)).slice(-2);\n                        })\n                            .join(\"\"));\n                        return JSON.parse(jsonPayload);\n                    };\n                    const token = ((_a = document.cookie.match(\"(^|;)\\\\s*\" + \"auth\" + \"\\\\s*=\\\\s*([^;]+)\")) === null || _a === void 0 ? void 0 : _a.pop()) || \"\";\n                    if (token.length > 0) {\n                        const userId = (_b = parseJwt(token)) === null || _b === void 0 ? void 0 : _b.user_id;\n                        if (userId) {\n                            return { platform: \"dietdoctor-frontend\", userId: userId };\n                        }\n                    }\n                    return { platform: \"dietdoctor-frontend\" };\n                }"},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"mergeSecurityHeaders":false,"mergeLinkHeaders":false,"mergeCachingHeaders":true,"headers":{"/*":["Access-Control-Allow-Origin: *"],"/iframe/*":["X-Frame-Options: SAMEORIGIN"],"/fonts/*":["Cache-Control: public, max-age=31536000, s-maxage=31536000, immutable"],"/static/*":["Cache-Control: public, max-age=31536000, s-maxage=31536000, immutable"]},"allPageHeaders":["Strict-Transport-Security: max-age=31536000; includeSubDomains; preload"]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
