import { createTheme } from "@mui/material/styles";
import { default as colors } from "../../utils/colors.json";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  },

  palette: {
    primary: {
      main: colors.BLUE,
    },
    secondary: {
      main: colors.PINK,
    },
  },

  typography: {
    fontFamily: "Diet Doctor Sans, Trebuchet MS, Arial, sans-serif",
    fontSize: 18,
    body1: {
      fontSize: 16,
      margin: 0,
    },
    body2: {
      fontSize: 16,
      margin: 0,
    },
    h1: {
      fontSize: "1.875rem",
      fontWeight: 500,
      margin: 0,
    },
    h2: {
      fontSize: "1.875rem",
      fontWeight: 500,
      margin: 0,
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 500,
      margin: 0,
    },
    h4: {
      fontSize: "1.125rem",
      fontWeight: 500,
      margin: 0,
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 500,
      margin: 0,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
      margin: 0,
    },
  },

  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 24,
          fontSize: 18,
          fontWeight: 500,
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          padding: "5px 25px",
        },
        containedSecondary: {
          "&:hover": {
            backgroundColor: colors.PINK_LIGHT,
          },
        },
        outlinedPrimary: {
          "&:hover": {
            backgroundColor: "transparent",
            border: `2px solid ${colors.BLUE_LIGHT}`,
            color: colors.BLUE_LIGHT,
          },
          border: `2px solid`,
          padding: "3px 25px",
        },
        outlinedSecondary: {
          "&:hover": {
            backgroundColor: "transparent",
            border: `2px solid ${colors.PINK_LIGHT}`,
            color: colors.PINK_LIGHT,
          },
          border: `2px solid`,
          padding: "3px 25px",
        },
      },
    },
  },
});

export default theme;
