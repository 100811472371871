import { IMembershipSubscriptionProvider, IMembershipSubscriptionStatus, IUser } from "@dietdoctor/elements";
import { useMachine } from "@xstate/react";
import { useContext, useEffect } from "react";
import { assign, createMachine, DoneInvokeEvent } from "xstate";
import { GroupIdPayload, SubscriptionPayer } from "../../ecosystems/dd-pro/pro/types";
import { LoginContext } from "../../components/LoginProvider/LoginProvider";
import GetGroups from "../../graphql/DDPro/GetGroups.graphql";
import {
  GetEssentialUserInfo_me,
  GetEssentialUserInfo_membershipSubscription,
} from "../../types/GetEssentialUserInfo";
import { MembershipSubscriptionStatus } from "../../types/graphql-global-types";
import { client } from "../../utils/apollo-client";

type UserChangedEvent = {
  type: "USER_CHANGED";
  user?: GetEssentialUserInfo_me;
  subscription?: GetEssentialUserInfo_membershipSubscription;
};

type LogoutEvent = {
  type: "LOG_OUT";
};

type UserEvent = UserChangedEvent | LogoutEvent;

type UserContext = {
  user?: IUser;
};

const proSubscriptionPayerToInt = (s: SubscriptionPayer) =>
  s === SubscriptionPayer.ADMIN ? 1 : s === SubscriptionPayer.MEMBER ? 2 : 0;

const machine = createMachine<UserContext, UserEvent>(
  {
    predictableActionArguments: true,
    context: {},
    initial: "getUserStatus",
    states: {
      getUserStatus: {
        on: {
          USER_CHANGED: [
            {
              cond: "isActiveMember",
              actions: ["setUser"],
              target: "getProStatus",
            },
            {
              cond: "isNotActiveMember",
              actions: ["setUser"],
              target: "ready",
            },
            {
              target: "ready",
            },
          ],
        },
      },
      getProStatus: {
        invoke: {
          src: "loadProGroups",
          onError: {
            actions: [(_, e) => console.log(e)],
          },
          onDone: [
            {
              target: "ready",
              actions: ["setPro"],
              cond: "isPro",
            },
            {
              target: "ready",
            },
          ],
        },
      },
      ready: {
        on: {
          LOG_OUT: "logout",
        },
      },
      logout: {
        invoke: {
          src: "logout",
          onError: {
            // sometimes firebase returns an error, but that's ok, the user is logged out
            actions: ["setUser", "navigateToLogin"],
          },
          onDone: {
            actions: ["setUser", "navigateToLogin"],
          },
        },
      },
    },
  },
  {
    guards: {
      isPro: (_, e) => (e as DoneInvokeEvent<{ data: GroupIdPayload }>).data.data.getDDProGroups.length > 0,
      isActiveMember: (_, e) =>
        (e as UserChangedEvent).subscription?.status === MembershipSubscriptionStatus.ACTIVE,
      isNotActiveMember: (_, e) =>
        (e as UserChangedEvent).subscription?.status !== MembershipSubscriptionStatus.ACTIVE,
    },
    services: {
      loadProGroups: () => client.query({ query: GetGroups }),
    },
    actions: {
      setPro: assign({
        user: (ctx, e) => {
          const [group] = (e as DoneInvokeEvent<{ data: GroupIdPayload }>).data.data.getDDProGroups;
          return ctx.user
            ? {
                ...ctx.user,
                pro: {
                  group: {
                    id: group.id,
                    title: group.title,
                    subscriptionPayer: proSubscriptionPayerToInt(group.subscriptionPayer),
                  },
                },
              }
            : undefined;
        },
      }),
      setUser: assign({
        user: (_, e) => {
          const event = e as UserChangedEvent;
          return event.user
            ? {
                firstName: event.user?.firstName,
                lastName: event.user?.lastName,
                membershipSubscription: {
                  status:
                    (event.subscription?.status as unknown as IMembershipSubscriptionStatus) ||
                    IMembershipSubscriptionStatus.UNKNOWN,
                  provider:
                    (event.subscription?.provider as unknown as IMembershipSubscriptionProvider) ||
                    IMembershipSubscriptionProvider.UNKNOWN,
                },
              }
            : undefined;
        },
      }),
    },
  }
);

export function useUser(onLogout?: () => void) {
  const { loggedIn, loading, user, subscription, firebaseUserId, logout } = useContext(LoginContext);

  const [state, send] = useMachine(machine, {
    services: {
      logout: () => logout(),
    },
    actions: {
      navigateToLogin: () => onLogout?.(),
    },
  });

  useEffect(() => {
    if (loggedIn && user) {
      send({ type: "USER_CHANGED", user, subscription });
    }
  }, [loggedIn, user, subscription, send]);

  return {
    id: user?.userId,
    firebaseUserId,
    isPremium: subscription?.status === MembershipSubscriptionStatus.ACTIVE,
    send,
    loggedIn,
    loading,
    subscription,
    logout,
    user: state.context.user,
  };
}
