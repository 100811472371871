export interface Dimension {
  width: number;
  height: number;
}

export const getSrc = (path: string, dimension: Dimension) =>
  `https://i.dietdoctor.com${path}?auto=compress%2Cformat&w=${dimension.width}&h=${dimension.height}&fit=crop`;

export const getSrcWithWidth = (path: string, dimension: Pick<Dimension, "width">) =>
  `https://i.dietdoctor.com${path}?auto=compress%2Cformat&w=${dimension.width}&fit=crop`;

export const getSrcSet = (path: string, dimensions: Dimension[]) => {
  return dimensions.map((size) => `${getSrc(path, size)} ${size.width}w`).join(", ");
};
