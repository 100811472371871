import React, { ReactNode } from "react";
import { isDevelopment } from "../../utils/isDevelopment";
import { Helmet } from "react-helmet";
import { Directus } from "../../hooks/useDirectus/useDirectus";
import { client } from "../../utils/apollo-client";
import { ApolloProvider } from "@apollo/client";
import { LoginProvider } from "../LoginProvider/LoginProvider";

export const RootWrapper: React.FunctionComponent<{ children?: React.ReactNode }> = ({ children }) => {
  const doNotIndex = isDevelopment();
  return (
    <>
      <Helmet>
        {/* No index */}
        {doNotIndex && <meta id="robots-index" name="robots" content="noindex" />}

        {/* Fonts */}
        <link
          rel="preload"
          href="/fonts/DietDoctorSans-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/DietDoctorSans-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="/fonts/DietDoctorSans-Italic.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />
      </Helmet>
      <Directus>
        <ApolloProvider client={client}>
          <LoginProvider>{children}</LoginProvider>
        </ApolloProvider>
      </Directus>
    </>
  );
};

interface WrapRootElementParams {
  element: ReactNode;
}

// ts-prune-ignore-next
export function wrapRootElement({ element }: WrapRootElementParams) {
  return <RootWrapper>{element}</RootWrapper>;
}
