import { i18n } from "@lingui/core";
import { en as pluralsEn, es as pluralsEs, sv as pluralsSv } from "make-plural/plurals";
import { messages as messagesEn } from "../locales/en/messages";
import { messages as messagesEs } from "../locales/es/messages";
import { messages as messagesSv } from "../locales/sv/messages";
import { messages as messagesZu } from "../locales/zu/messages";
import { Locale } from "../types/Locale";

export function setupI18n(initialLocale = Locale.EN) {
  i18n.loadLocaleData({
    en: { plurals: pluralsEn },
    es: { plurals: pluralsEs },
    sv: { plurals: pluralsSv },
    zu: { plurals: pluralsEn },
  });
  i18n.load({
    en: messagesEn,
    es: messagesEs,
    sv: messagesSv,
    zu: messagesZu,
  });

  i18n.activate(initialLocale);
}
