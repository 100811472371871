import React, { ReactNode, useContext } from "react";
import { i18nContext } from "../I18nProvider/I18nProvider";
import PageSection, { PageSectionBody, PageSectionTitle } from "../PageSection/PageSection";

function useTranslations() {
  const {
    translations: {
      errors: {
        default: { title, message },
      },
    },
  } = useContext(i18nContext);

  return {
    defaultTitle: title,
    defaultMessage: message,
  };
}

export const BaseErrorPage = ({ title, children }: { title: string; children: ReactNode }) => (
  <>
    <PageSection>
      <PageSectionTitle variant="small">{title}</PageSectionTitle>
      <PageSectionBody>{children}</PageSectionBody>
    </PageSection>
  </>
);

interface ErrorPageWithImageProps {
  title?: string;
  message?: string;
}

const ErrorPageWithImage = (props: ErrorPageWithImageProps) => {
  const tt = useTranslations();
  const title = props.title || tt.defaultTitle;
  const message = props.message || tt.defaultMessage;

  return (
    <BaseErrorPage title={title}>
      <p>{message}</p>
    </BaseErrorPage>
  );
};

export default ErrorPageWithImage;
